import React, { useEffect, useState } from "react";
import { Popper, Paper, ClickAwayListener } from "@mui/material";
import { Editor, Range, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import useDrag from "../../hooks/useDrag";
import useWindowResize from "../../hooks/useWindowResize";
import MiniTextFormat from "./MiniTextFormat";
import { useEditorContext } from "../../hooks/useMouseMove";
import usePopupStyles from "../PopupTool/PopupToolStyle";
import useEditorScroll from "../../hooks/useEditorScroll";
import { isCarouselSelected } from "../../helper";
import { hideSlateSelection } from "../../utils/helper";

const PopupTool = (props) => {
  const { theme, setIsTextSelected, customProps, editorWrapper } = props;
  const classes = usePopupStyles(theme);
  const { setPopupType, openAI, selectedElement } = useEditorContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const editor = useSlate();
  const { selection } = editor;
  const [event] = useDrag(anchorEl);
  const id = open ? "popup-edit-tool" : "";
  const [size] = useWindowResize();

  const updateAnchorEl = (isScroll) => {
    try {
      const isHavingSelection = selection && !Range.isCollapsed(selection);
      if (isHavingSelection && event === "end") {
        const domRange = ReactEditor.toDOMRange(editor, editor.selection);

        const editorContainer = document
          .querySelector("#slate-wrapper-scroll-container")
          ?.getBoundingClientRect();

        const rect = domRange.getBoundingClientRect();

        const isOutside =
          rect.bottom < editorContainer.top ||
          rect.top > editorContainer.bottom;

        if (isOutside) {
          rect.y = -500; // hide the popper
        }

        // Create a dummy anchor element to match Popper's requirements
        const anchor = document.createElement("div");
        anchor.style.position = "absolute";
        anchor.style.top = `${rect.top + window.scrollY}px`;
        anchor.style.left = `${rect.left + window.scrollX}px`;
        document.body.appendChild(anchor);

        if (!anchorEl || isScroll === "scroll") {
          setAnchorEl(anchor);
          setOpen(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEditorScroll(editorWrapper, updateAnchorEl);

  useEffect(() => {
    const userStoppedSelection = size?.device === "xs" ? true : event === "end"; // for mobile, when user starts the selection, we are gonna show the popup tool

    if (userStoppedSelection && anchorEl && !open) {
      // for table cell selection
      const isCarouselEdit = isCarouselSelected(editor);
      // const isCellsSelected = table.isCellSelected(editor.selection) || isCarouselEdit;
      if (isCarouselEdit) {
        Transforms.deselect(editor);
      }
      if (!isCarouselEdit) {
        setOpen(true);
        setPopupType("textFormat");
        setIsTextSelected(true);
      }
    } else if (!anchorEl) {
      setOpen(false);
      setPopupType("");
      setIsTextSelected(false);
    }
  }, [event, anchorEl]);

  useEffect(() => {
    if (
      !selection ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === "" ||
      selectedElement?.enable === 1
    ) {
      setAnchorEl(null);
    } else {
      updateAnchorEl();
      hideSlateSelection(); // removes slate selection background, when there is no selection
    }
  }, [selection, event, selectedElement?.enable]);

  const handleClose = () => {
    // setAnchorEl(null);
    setOpen(false);
    setPopupType("");
  };

  return open && !openAI ? (
    <ClickAwayListener
      onClickAway={(e) => {
        // close the mini toolbar, if user clicks outside the editor (in Flozy app.)
        if (e.target !== document.body) {
          // e.target returns body, if the user clicks material ui select popup inside the tool bar, on that time, we don't need to close
          handleClose();
        }
      }}
    >
      {size.device === "xs" ? (
        <div className="mobileMiniTextWrapper">
          <MiniTextFormat
            editor={editor}
            classes={classes}
            closeMainPopup={handleClose}
            customProps={customProps}
          />
        </div>
      ) : (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={classes.popupWrapper}
          placement="top-start"
        >
          <Paper style={{ borderRadius: "6px", border: "1px solid #8360FD" }}>
            <MiniTextFormat
              editor={editor}
              classes={classes}
              closeMainPopup={handleClose}
              customProps={customProps}
            />
          </Paper>
        </Popper>
      )}
    </ClickAwayListener>
  ) : null;
};

export default PopupTool;
