import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Paper,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Style
import Style from "./style";
import ThemeTextFormat from "../../Toolbar/PopupTool/ThemeTextFormat";
import { useEditorContext } from "../../hooks/useMouseMove";
import usePopupStyle from "../../Toolbar/PopupTool/PopupToolStyle";
import useWindowResize from "../../hooks/useWindowResize";
import PopperHeader from "../../Toolbar/PopupTool/PopperHeader";
import { useEditorTheme } from "../../hooks/useEditorTheme";
import {
  HEADING_THEME_FIELDS,
  PARAGRAPH_THEME_FIELDS,
} from "../../helper/theme";
import PreviewElement from "./PreviewElement";
import FontFamilyAutocomplete from "../../Toolbar/FormatTools/FontFamilyAutocomplete";
import { googleFontList as defaultFonts } from "../../common/FontLoader/FontList";

const Fonts = (props) => {
  const { className } = props;

  const [anchorEl, setAnchorEl] = React.useState();
  const [selectedField, setSelectedField] = React.useState("");
  const [settings, setSettings] = React.useState({});
  const updateSettings = (update) => {
    setSettings((prev) => {
      return {
        ...prev,
        ...update,
      };
    });
  };

  const onClose = () => {
    setAnchorEl(null);
    setSelectedField("");
    setSettings({});
  };

  const { theme, fontFamilies } = useEditorContext();
  const classes = usePopupStyle(theme);
  const [size] = useWindowResize();

  const { selectedTheme, updateTheme } = useEditorTheme();
  const { otherProps = {} } = selectedTheme || {};
  const {
    isHeadingCustomized,
    headingFontFamily,
    isParagraphCustomized,
    paragraphFontFamily,
  } = otherProps;

  const onSaveTextSettings = () => {
    updateTheme(settings, {
      action: "ELEMENT_PROPS_CHANGE",
      fieldName: selectedField,
    });

    onClose();
  };

  const updateOtherThemeProps = (update) => {
    updateTheme(update, {
      action: "OTHER_PROPS_CHANGE",
    });
  };

  const updateFont = (update, fieldType) => {
    updateTheme(update, {
      action: "FONT_CHANGE",
      fieldType,
    });
  };

  const tabs = [
    {
      title: "Heading Font family",
      onFontFamilyChange: ({ fontFamily }) => {
        updateFont({ fontFamily }, "HEADING");
        updateSettings({ fontFamily });
      },
      fontFamily: headingFontFamily || defaultFonts[0],

      isCustomized: isHeadingCustomized,
      onCustomizeClick: (event) => {
        updateOtherThemeProps({
          isHeadingCustomized: event.target.checked,
        });
      },
      fields: HEADING_THEME_FIELDS,
    },
    {
      title: "Paragraph Font family",
      onFontFamilyChange: ({ fontFamily }) => {
        updateFont({ fontFamily }, "PARAGRAPH");
        updateSettings({ fontFamily });
      },
      fontFamily: paragraphFontFamily || defaultFonts[0],

      isCustomized: isParagraphCustomized,
      onCustomizeClick: (event) => {
        updateOtherThemeProps({
          isParagraphCustomized: event.target.checked,
        });
      },
      fields: PARAGRAPH_THEME_FIELDS,
    },
  ];

  return (
    <Grid className={className}>
      <Accordion className="settingAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Fonts
        </AccordionSummary>
        <AccordionDetails>
          {tabs?.map((tab, i) => {
            const {
              title,
              onFontFamilyChange,
              fontFamily,
              isCustomized = "",
              onCustomizeClick,
              fields,
            } = tab;

            return (
              <React.Fragment key={i}>
                <Grid sx={{ pt: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{ pb: 1 }}
                    className="fs-14 fw-500"
                  >
                    {title}
                  </Typography>

                  <FontFamilyAutocomplete
                    editor={{}}
                    format={"fontFamily"}
                    options={fontFamilies.options}
                    width={"100%"}
                    onChange={onFontFamilyChange}
                    val={fontFamily}
                    webFont
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCustomized}
                        onChange={onCustomizeClick}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="body2" className="fs-12">
                        Customize Font style
                      </Typography>
                    }
                  />

                  {isCustomized && (
                    <Grid ref={anchorEl}>
                      {fields.map((field, index) => {
                        return (
                          <PreviewElement
                            key={index}
                            field={field}
                            settings={settings}
                            setSettings={setSettings}
                            setAnchorEl={setAnchorEl}
                            selectedField={selectedField}
                            setSelectedField={setSelectedField}
                          />
                        );
                      })}
                    </Grid>
                  )}
                </Grid>

                {i === 0 && <Divider sx={{ mt: 1, mb: 1 }} />}
              </React.Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        placement="left-start"
        sx={classes.popupWrapper}
        className={`tools-drawer ${size?.device} textSettings`}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper style={{ borderRadius: "10px", position: "relative" }}>
              <PopperHeader
                title={"Text Settings"}
                classes={classes}
                onClose={onClose}
              />
              <ThemeTextFormat
                settings={settings}
                updateSettings={updateSettings}
                onSaveTextSettings={onSaveTextSettings}
                onClose={onClose}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
};

export default styled(Fonts)(Style);
