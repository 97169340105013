const VariableStyles = (theme) => ({
  variablesItem: {
    color: "#2563EB",
    background: "#EEEEEE",
  },
  // variableSelectBtn: {
  //   height: "31px",
  //   borderRadius: "10px",
  //   marginLeft: "8px"
  // }
  variableBtn: {
    background: "#F4F6F9",
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500,
    padding: "4px 22px",
    textTransform: "none",
    border: "1px solid #D8DDE1",
    height: "36px",
    paddingLeft: "8px",
    paddingRight: "18px",
    "& svg": {
      width: "20px",
      height: "20px",
      "& path": {
        stroke: "#64748B",
      },
    },
    "&:hover": {
      border: "1px solid #64748B",
    },
    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
      paddingRight: "5px",
    },
  },
  variableMenuItem: {
    "& .MuiMenuItem-root": {
      color: "#64748B",
    },
    "& .MuiPaper-root":{
      borderRadius: '8px',
      backgroundColor: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
      marginTop: '5px',
      "@media only screen and (max-width: 599px)":{
        padding: '10px 0px'
      }
    },
    "& .MuiList-root":{
      padding: '0px'
    },
    "& .MuiButtonBase-root":{
      margin: '6px',
      borderRadius: '8px',
      padding: '6px 14px',
      fontSize: '14px',
      fontWeight: '400',
      color: theme?.palette?.editor?.deletePopUpButtonTextColor,
      "&:hover":{
        background: `${theme?.palette?.editor?.menuOptionHoverBackground} !important`
      },
      "@media only screen and (max-width: 599px)":{
        minHeight: '30px',
        margin: '0px 6px'
      }
    },
    "& .Mui-selected":{
      backgroundColor: 'unset !important'
    }
  },
});

export default VariableStyles;
