import React, { useState } from "react";
import { Box, IconButton, Popover, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "../../common/Button";
import { colors } from "./defaultColors";
import ColorPicker from "./colorPicker.svg";
import { useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { useEditorContext } from "../../hooks/useMouseMove";

let c = [];
const ColorChunks = (recentColors = []) =>
  [...recentColors, ...colors].reduce((a, b, i) => {
    if (i % 7 === 0) {
      if (c.length > 0) {
        a.push(c);
      }
      c = [];
    }
    c.push(b);
    return a;
  }, []);
const SingleColorButton = ({
  crs,
  index,
  handleSelect,
  classes,
  handleMore,
  activeColor,
  id,
  padding,
}) => {
  return (
    <Box
      key={`ci_bs_row_${index}}`}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: padding || "8px 0px",
        justifyContent: "center",
      }}
    >
      {crs.map((m) => (
        <Button
          onClick={handleSelect(m)}
          key={`color_bs_${m}`}
          style={{ background: m }}
          sx={classes.colorButtonSingle}
          className={`${activeColor === m ? "active" : ""}`}
        />
      ))}
      {index === 0 && handleMore ? (
        <IconButton
          id={`in_${id}`}
          onClick={handleMore}
          className="more-btn-cbs"
        >
          <KeyboardArrowDownRoundedIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export const AllColors = (props) => {
  const { id, classes, open, anchorEl, onClose, onSelect, activeColor } = props;
  const all = ColorChunks([]);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={onClose}
      sx={classes.colorPopper}
    >
      <Box component={"div"} sx={classes.allColor}>
        <Box sx={classes.allColorInner}>
          <Box className="buttonsWrpr" style={{ padding: "5px" }}>
            {all.map((m, i) => {
              return (
                <SingleColorButton
                  id={`pi_${id}`}
                  key={`si_btn_row1_${m}_${i}`}
                  crs={m}
                  index={i}
                  handleSelect={onSelect}
                  classes={classes}
                  activeColor={activeColor}
                  padding="0px"
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

const ColorButtons = (props) => {
  const {
    id,
    onSelect,
    classes,
    activeColor,
    forMiniTool,
    openColorTool,
    onClose,
    onColorPickerClick,
    disableEditTheme,
    defaultColors = [],
  } = props;
  const [row1, ...restRows] = ColorChunks([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const firstRow = defaultColors?.length ? defaultColors : row1;

  const theme = useTheme();
  const editor = useSlateStatic();
  const { setOpenTheme } = useEditorContext();

  const handleMore = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    if (onClose) {
      onClose();
    }
  };

  const handleSelect = (color) => () => {
    onSelect(color);
  };

  const colorVars = theme?.vars?.colors || {};

  return (
    <Box component={"span"} sx={classes.colorButtons}>
      {forMiniTool ? null : (
        <Box className="buttonsWrpr first">
          {[firstRow].map((m, i) => {
            return (
              <SingleColorButton
                id={`p1_${id}`}
                key={`si_btn_row1_${m}_${i}`}
                crs={m}
                index={i}
                handleSelect={handleSelect}
                classes={classes}
                handleMore={handleMore}
                activeColor={activeColor}
              />
            );
          })}
        </Box>
      )}

      <Popover
        open={open || openColorTool}
        anchorEl={anchorEl || openColorTool}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={classes.colorPopper}
        className="colorPopper"
      >
        <Box sx={classes.colorButtonsInner}>
          {Object.values(colorVars)?.length ? (
            <>
              <Box component={"div"} className="singleColorTitleWrapper">
                <Typography variant="subtitle2">Theme colours</Typography>
                {disableEditTheme ? null : (
                  <div
                    className="editBtn"
                    onClick={() => {
                      Transforms.deselect(editor, { at: editor.selection });
                      setOpenTheme("editThemeColor");
                    }}
                  >
                    Edit
                  </div>
                )}
              </Box>

              <SingleColorButton
                crs={Object.values(colorVars)}
                handleSelect={handleSelect}
                classes={classes}
                activeColor={activeColor}
              />
            </>
          ) : null}

          <Box component={"div"} className="singleColorTitleWrapper">
            <Typography variant="subtitle2">Custom colour</Typography>
          </Box>

          {restRows.map((m, i) => {
            return (
              <SingleColorButton
                id={`p2_${id}`}
                key={`si_btn_${m}_${i}`}
                crs={m}
                index={i}
                handleSelect={handleSelect}
                classes={classes}
                activeColor={activeColor}
              />
            );
          })}

          {forMiniTool ? (
            <IconButton
              onClick={onColorPickerClick}
              style={{ alignSelf: "start", margin: "6px" }}
            >
              <img src={ColorPicker} alt="color wheel" />
            </IconButton>
          ) : null}
        </Box>
      </Popover>
    </Box>
  );
};

export default ColorButtons;
