import { Transforms, Node } from "slate";
import { ReactEditor } from "slate-react";
import { getNearestItem } from "./calculateDropItem";
import { ROW_HEIGHT, getBorderWidth } from "./gridDropItem";
import { handleNegativeInteger } from "../../../utils/helper";

const GUIDE_LINE_THRESHOLD = 5;
const GUIDE_LINE_OVERLAP_THRESHOLD = 5;

const handleMoveNode = (editor, path, newPath, { isEmpty }) => {
  try {
    const replaceNode = Node.get(editor, path);
    if (isEmpty) {
      const toPath = [...newPath, 0];
      Transforms.insertNodes(editor, [{ ...replaceNode }], {
        at: toPath,
      });
      Transforms.removeNodes(editor, { at: path });
      return toPath;
    } else {
      Transforms.insertNodes(editor, [{ ...replaceNode }], {
        at: newPath,
      });
      Transforms.removeNodes(editor, { at: path });
      return newPath;
    }
  } catch (err) {
    console.log(err);
    console.log("Drop Node error");
    return null;
  }
};

export function triggerClick(editor, path = []) {
  try {
    const triggerNode = Node.get(editor, path);
    const triggerNodeDOM = ReactEditor.toDOMNode(editor, triggerNode);
    triggerNodeDOM?.closest(".freegrid-item")?.click();
  } catch (err) {
    console.log(err);
  }
}

export function updatePositions(props, closestClass) {
  try {
    const { editor, path, offsetY, dragOver, x, y, diffX, parentPath } = props;
    const posY = y - offsetY;
    const posX = parseInt(x - window.innerWidth / 2 + 490 - diffX);
    let addToSectionDOMRect = null;
    let newPath = [];
    let toSectionNode = null;
    const needMove = dragOver !== parentPath;
    const moveTo = dragOver.split("|").map((m) => parseInt(m));
    const from = parentPath.split("|").map((m) => parseInt(m));
    newPath = moveTo;
    toSectionNode = Node.get(editor, newPath);
    const addToSectionDOM = ReactEditor.toDOMNode(editor, toSectionNode);
    addToSectionDOMRect = addToSectionDOM?.getBoundingClientRect();

    // update top and left properties
    const newProperties = {};
    newProperties.left = posX;
    newProperties.marginTop =
      posY > addToSectionDOMRect.top
        ? posY - addToSectionDOMRect.top
        : addToSectionDOMRect.top - posY;

    Transforms.setNodes(editor, newProperties, { at: path });
    const returnData = {};
    if (needMove) {
      const isEmpty = toSectionNode?.children[0]?.type === undefined;
      if (!isEmpty) {
        newPath = [...newPath, toSectionNode?.children?.length];
      }
      newPath = newPath.map((m) => parseInt(m));
      const rPath = handleMoveNode(editor, path, newPath, { isEmpty });
      // to update path index need to re-render items in parent sections
      Transforms.setNodes(
        editor,
        { updated_at: new Date().getTime },
        { at: parentPath.split("|").map((m) => parseInt(m)) }
      );
      returnData.updated_at = rPath;
    }
    returnData.updated_at = path;
    if (needMove) {
      getNearestItem(editor, moveTo, { currentItem: path });
    } else {
      getNearestItem(editor, from, { currentItem: path });
    }
    return returnData;
  } catch (err) {
    console.log(err);
  }
}

const isOverLapLine = ({ x, y }, lines) => {
  return lines.find(
    (f) =>
      Math.abs(f.x - x) <= GUIDE_LINE_OVERLAP_THRESHOLD ||
      Math.abs(f.y - y) <= GUIDE_LINE_OVERLAP_THRESHOLD
  );
};

export function getClosestDraggable(x, y, className, activeClassName) {
  const draggables = document.querySelectorAll(className);
  const activeDragEle = document.querySelectorAll(activeClassName)[0];
  const {
    left: aLeft,
    top: aTop,
    width: aWidth,
    height: aHeight,
  } = activeDragEle?.getBoundingClientRect() || {};
  let lines = [];
  draggables.forEach((draggable) => {
    const { left, top, width, height } = draggable.getBoundingClientRect();
    let xVal = 0;
    let yVal = 0;

    // top match
    xVal = x < left ? aLeft : left;
    yVal = top;
    if (
      Math.abs(top - aTop) <= GUIDE_LINE_THRESHOLD &&
      !isOverLapLine({ x: xVal, y: yVal }, lines)
    ) {
      lines.push({
        y: top,
        x: xVal,
        width:
          x > left
            ? Math.abs(aLeft + aWidth - left)
            : Math.abs(aLeft - (left + width)),
        height: 1,
      });
    }

    // bottom match
    xVal = x < left ? aLeft : left;
    yVal = top + height;
    if (
      Math.abs(top + height - (aTop + aHeight)) <= GUIDE_LINE_THRESHOLD &&
      !isOverLapLine({ x: xVal, y: yVal }, lines)
    ) {
      lines.push({
        y: yVal,
        x: xVal,
        width:
          x > left
            ? Math.abs(aLeft + aWidth - left)
            : Math.abs(aLeft - (left + width)),
        height: 1,
      });
    }

    // center match
    xVal = x < left ? aLeft : left;
    yVal = top + height / 2;
    if (
      Math.abs(top + height / 2 - (aTop + aHeight / 2)) <=
        GUIDE_LINE_THRESHOLD &&
      !isOverLapLine({ x: xVal, y: yVal }, lines, "y")
    ) {
      lines.push({
        y: yVal,
        x: xVal,
        width:
          x > left
            ? Math.abs(aLeft + aWidth - left)
            : Math.abs(aLeft - (left + width)),
        height: 1,
      });
    }

    // right match
    xVal = left + width;
    yVal = top < aTop ? top : aTop;
    if (
      (Math.abs(left + width - aLeft) <= GUIDE_LINE_THRESHOLD ||
        Math.abs(left - aLeft) <= GUIDE_LINE_THRESHOLD) &&
      !isOverLapLine({ x: xVal, y: yVal }, lines)
    ) {
      lines.push({
        y: yVal,
        x: xVal,
        width: 1,
        height: Math.abs(aTop - top),
      });
    }

    // left match
    xVal = left;
    yVal = top < aTop ? top : aTop;
    if (
      (Math.abs(aLeft + aWidth - left) <= GUIDE_LINE_THRESHOLD ||
        Math.abs(aLeft - left) <= GUIDE_LINE_THRESHOLD) &&
      !isOverLapLine({ x: xVal, y: yVal }, lines)
    ) {
      lines.push({
        y: yVal,
        x: xVal,
        width: 1,
        height: Math.abs(aTop - top),
      });
    }

    // middle match
    xVal = left + width / 2;
    yVal = top < aTop ? top : aTop;
    if (
      Math.abs(aLeft + aWidth / 2 - (left + width / 2)) <=
        GUIDE_LINE_THRESHOLD &&
      !isOverLapLine({ x: xVal, y: yVal }, lines)
    ) {
      lines.push({
        y: yVal,
        x: xVal,
        width: 1,
        height: Math.abs(aTop - top),
      });
    }
  });
  return lines;
}

export function isDragOver(rect, mousePosition) {
  const { x, y } = mousePosition;
  if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
    return true;
  }
  return false;
}

export function getParentSectionPath(props, closestClass) {
  try {
    const { ref } = props;
    const parentDom = ref?.closest(closestClass);
    return parentDom?.dataset?.path;
  } catch (err) {
    console.log(err);
  }
}

export function getAbsolutePositionX(currentEle) {
  const { left: currElementLeft } = currentEle?.getBoundingClientRect() || {};

  const parentBoxDom = currentEle?.closest(".fgi_type_box");

  const relativeElementX =
    parentBoxDom || document.querySelector(".rnd-guideline-lv");

  const { left } = relativeElementX?.getBoundingClientRect() || {};
  const borderLeftWidth = getBorderWidth(relativeElementX, "borderLeftWidth");

  const absolutePositionX = parseInt(currElementLeft - left - borderLeftWidth);

  return { absolutePositionX };
}

export function getAbsolutePositionY(currentEle) {
  const { top: currElementTop } = currentEle?.getBoundingClientRect() || {};

  const relativeElementY = currentEle?.closest(".freegrid-container-parent");

  const borderTopWidth = getBorderWidth(relativeElementY, "borderTopWidth");

  const rect = relativeElementY.getBoundingClientRect();
  const absolutePositionY = handleNegativeInteger(
    currElementTop - rect.top - borderTopWidth
  );

  // Calculate grid position
  const row = Math.floor(absolutePositionY / ROW_HEIGHT) + 1;

  // Update grid area
  const gridArea = `${row} / 1 / ${row + 1} / 2`;

  // to calculate difference inside the grid
  const marginTop = Math.abs((row - 1) * ROW_HEIGHT - absolutePositionY);

  return { absolutePositionY, gridArea, marginTop };
}
