import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewportStimulator from "./common/RnD/Theme/ViewportStimulator";
import MainThemeProvider from "./common/RnD/Theme/MainThemeProvider";

const DialogWrapper = (props) => {
  const { fullScreen, onClose, children, classes, selectedTheme } = props;

  return fullScreen ? (
    <MainThemeProvider userSelectedTheme={selectedTheme}>
      <Dialog
        className={`dialogComp`}
        open={fullScreen}
        fullScreen={fullScreen}
        onClose={onClose}
        style={{ zIndex: 1000 }}
        sx={classes.fullScreenWrapper}
      >
        <DialogTitle style={{ padding: "6px 8px" }}>
          <Grid>
            <Grid style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                onClick={onClose}
                style={{ background: "rgba(255,255,255,0.5)" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </MainThemeProvider>
  ) : (
    <ViewportStimulator selectedTheme={selectedTheme}>
      {children}
    </ViewportStimulator>
  );
};

DialogWrapper.defaultProps = {
  fullScreen: false,
  onClose: () => {},
  children: "",
  footer: "",
};

// export default styled(commonStyle)(DialogWrapper);
export default DialogWrapper;
