const otherFonts = [
    "PoppinsRegular",
    "PoppinsBold",
    "Qwitcher Grypen",
    "Bulgarian Garamond",
    "Redacted Script",
    "Herr Von Muellerhoff",
    "Dawning of a New Day",
    "Coming Soon",
    "Engagement",
    "Ingrid Darling",
    "La Belle Aurore",
    "Mea Culpa",
    "The Girl Next Door"
];

const mostUsedGoogleFonts = [
    "Roboto",
    "Poppins",
    "Lato",
    "Inter",
    "Nunito",
    "Ubuntu",
    "Oswald",
    "Rubik",
    "Roboto Slab",
    "PT Sans",
    "Work Sans",
    "Lora",
    "Mulish",
    "DM Sans",
    "Fira Sans",
    "Quicksand",
    "Barlow",
    "Manrope",
    "IBM Plex Sans",
    "PT Serif",
    "Libre Franklin",
    "Bebas Neue",
    "Cabin",
    "Titillium Web",
    "Heebo",
    "Noto Serif",
    "Jost",
    "Source Code Pro",
    "Josefin Sans",
    "Dosis",
    "Fira Sans Condensed",
    "Archivo",
    "Noto Serif JP",
    "Crimson Text",
    "Cairo",
    "Pacifico",
    "Red Hat Display",
    "Assistant",
    "Comfortaa",
    "Lexend",
    "Fjalla One",
    "Caveat",
    "Arvo",
    "Lobster",
    "Schibsted Grotesk",
    "EB Garamond",
    "Sora",
    "Kalam",
    "Onest",
    "Space Grotesk",
    "Outfit",
    "Plus Jakarta Sans"
];

export const googleFontList = [
    ...mostUsedGoogleFonts,
    ...otherFonts
]



