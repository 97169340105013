import React from "react";
import { TextField } from "@mui/material";
import { useDataView } from "../../Providers/DataViewProvider";
import { focusDynamicTableInput } from "../../../../utils/helper";

const TextType = (props) => {
  const { rowIndex, property, value, readOnly } = props;
  const { onChange } = useDataView();

  const handleChange = (e) => {
    onChange(rowIndex, {
      [property]: e?.target?.value,
    });

    focusDynamicTableInput(e);
  };

  const formatValue = () => {
    if (typeof value === "string") {
      return value;
    } else if (value[0]) {
      return value?.map((m) => m.value).join(", ");
    }
  };

  return (
    <TextField
      fullWidth
      className="fe-tv-type_text"
      value={formatValue(value)}
      size="small"
      onChange={handleChange}
      disabled={readOnly}
      id={`tv-text-input-${rowIndex}-${property}`} // * should be unique
    />
  );
};

export default TextType;
