import React, { useState } from "react";
import { Box, IconButton, InputBase, Menu, MenuItem } from "@mui/material";
import { useDataView } from "../Providers/DataViewProvider";
import FilterSort from "./FilterSort";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Icon from "../../../common/Icon";
import { useEditorContext } from "../../../hooks/useMouseMove";
import { focusDynamicTableInput } from "../../../utils/helper";

const FilterView = (props) => {
  const {
    classes,
    onDelete,
    onDuplicate,
    onEnter,
    readOnly,
    title,
    onTitleChange,
    path,
  } = props;
  const { sort, selectedRows, onDeleteRows, search, onSearch } = useDataView();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMoreEl, setAnchorMoreEl] = useState(null);
  const [mode, setMode] = useState({});
  const [toggle, setToggle] = useState(false);
  const open = Boolean(anchorEl);
  const openMore = Boolean(anchorMoreEl);
  const { theme } = useEditorContext();

  const handleSortClick = (e) => {
    setAnchorEl(e?.currentTarget);
    setMode({
      type: "sort",
    });
  };

  const handleMoreClick = (e) => {
    setAnchorMoreEl(e?.currentTarget);
    setMode({
      type: "",
    });
  };

  const handleDeleteRow = () => {
    onDeleteRows();
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const toggleSearch = () => {
    setToggle(!toggle);
  };

  const onMenuClick = (menu) => () => {
    switch (menu) {
      case "Duplicate":
        onDuplicate();
        break;
      case "Delete":
        onDelete();
        break;
      default:
    }
  };

  const handleMoreClose = () => {
    setAnchorMoreEl(null);
  };

  const handleTitleChange = (e) => {
    onTitleChange(e?.target?.value);

    focusDynamicTableInput(e);
  };

  const handleEnter = (e) => {
    if (e?.key === "Enter") {
      onEnter();
    }
  };

  return (
    <Box className={"fe-tv-fv"} contentEditable={false} sx={classes.filterView}>
      <Box className="tv-title-wrpr">
        {!readOnly ? (
          <InputBase
            sx={{ paddingBottom: "0px", fontWeight: "bold", fontSize: "16px" }}
            size="small"
            placeholder="Untitled"
            inputProps={{ "aria-label": "Table Name", maxLength: 100 }}
            value={title}
            onChange={handleTitleChange}
            onKeyUp={handleEnter}
            id={`tv-title-input-${path.join("|")}`}
          />
        ) : (
          title || "Untitled"
        )}
      </Box>
      <Box className="tv-fi-wrpr" sx={{ alignItems: "center" }}>
        <Box
          className={`tv-sb mr ${toggle ? "open" : ""}`}
          sx={{ minHeight: "32px" }}
        >
          <IconButton
            type="button"
            sx={{ "& svg": { width: "14px", height: "14px" } }}
            aria-label="search"
            onClick={toggleSearch}
          >
            <Icon icon="SearchIcon" />
          </IconButton>
          <InputBase
            sx={{ paddingBottom: "0px" }}
            size="small"
            placeholder="Search here"
            inputProps={{ "aria-label": "search google maps" }}
            value={search}
            onChange={onSearch}
          />
        </Box>
        <IconButton
          className={` ${sort?.length > 0 ? "active" : ""}`}
          onClick={handleSortClick}
          sx={{ "& svg": { strokeWidth: 1.2 } }}
        >
          <Icon icon={"sortBy"} />
        </IconButton>
        <FilterSort
          open={open}
          anchorEl={anchorEl}
          mode={mode}
          onClose={onClose}
        />
        {!readOnly ? (
          <IconButton
            className="mr"
            sx={{ "& svg": { fill: theme?.palette?.editor?.tv_stroke } }}
            onClick={handleMoreClick}
          >
            <MoreHorizIcon />
          </IconButton>
        ) : null}
        {selectedRows?.length > 0 && !readOnly ? (
          <IconButton className="mr" onClick={handleDeleteRow}>
            <Icon icon={"trashIcon"} />
          </IconButton>
        ) : null}
        {!readOnly ? (
          <Menu
            sx={classes.basicMenu}
            className="tv-basic-menu"
            anchorEl={anchorMoreEl}
            open={openMore}
            onClose={handleMoreClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={onMenuClick("Duplicate")}>
              {" "}
              <Box
                sx={{
                  marginRight: "5px",
                  display: " flex",
                  alignItems: "center",
                }}
              >
                <Icon icon={"duplicateIcon"} />
              </Box>
              Duplicate
            </MenuItem>
            <MenuItem onClick={onMenuClick("Delete")}>
              {" "}
              <Box
                sx={{
                  marginRight: "5px",
                  display: " flex",
                  alignItems: "center",
                }}
              >
                <Icon icon={"trashIcon"} />
              </Box>
              Delete
            </MenuItem>
          </Menu>
        ) : null}
      </Box>
    </Box>
  );
};

export default FilterView;
