import React from "react";
import { Node, Path, Transforms } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { Box, useTheme } from "@mui/material";
import { DataViewProvider } from "./Providers/DataViewProvider";
import useDataViewStyles from "./styles";
import LayoutView from "./Layouts";
import FilterView from "./Layouts/FilterView";
import { insertLineBreakAtEndOfPath } from "../../utils/helper";
import { useEditorContext } from "../../hooks/useMouseMove";

const DataView = (props) => {
  const { theme: appTheme } = useEditorContext();
  const theme = useTheme();
  const editor = useSlate();
  const { attributes, children, element, customProps } = props;
  const { CHARACTERS, readOnly } = customProps || {};
  const { properties, layouts, rows, title } = element;
  const classes = useDataViewStyles(theme, appTheme);
  const path = ReactEditor.findPath(editor, element);
  const users = CHARACTERS?.map((m) => {
    const name = [m?.first_name || "", m?.last_name || ""]?.join(" ").trim();
    return {
      value: m?.email,
      label: name || m?.username || m?.email,
      avatar: m?.avatar || null,
    };
  });

  const onDuplicate = () => {
    try {
      const newPath = ReactEditor.findPath(editor, element);
      const dataViewNode = Node.get(editor, newPath);
      const cloneNode = JSON.parse(JSON.stringify(dataViewNode));
      const toPath = Path.next(Path.parent(newPath));
      Transforms.insertNodes(
        editor,
        [
          {
            type: "paragraph",
            children: [
              {
                ...cloneNode,
              },
            ],
          },
        ],
        { at: toPath }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = () => {
    try {
      const newPath = ReactEditor.findPath(editor, element);
      Transforms.removeNodes(editor, { at: newPath });
    } catch (err) {
      console.log(err);
    }
  };

  const onEnter = () => {
    try {
      const newPath = ReactEditor.findPath(editor, element);
      const toPath = Path.previous(Path.parent(newPath));
      insertLineBreakAtEndOfPath(editor, toPath);
    } catch (err) {
      console.log(err);
    }
  };

  const onTitleChange = (value) => {
    try {
      Transforms.setNodes(editor, { title: value }, { at: path });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      {...attributes}
      className={"fe-dataview"}
      sx={classes.root}
      contentEditable={false}
      data-title={title}
    >
      <DataViewProvider
        data={{
          properties,
          layouts,
          rows,
        }}
        path={path}
        editor={editor}
        title={title}
        users={users}
      >
        <FilterView
          classes={classes}
          onEnter={onEnter}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          readOnly={readOnly}
          title={title}
          onTitleChange={onTitleChange}
          path={path}
        />
        <LayoutView readOnly={readOnly}>{children}</LayoutView>
      </DataViewProvider>
    </Box>
  );
};

export default DataView;
