import React from "react";
import Icon from "../../common/Icon";
import Button from "../../common/Button";
import {
  toggleMark,
  isMarkBtnActive,
} from "../../utils/SlateUtilityFunctions.js";

const MarkButton = ({ editor, format, title }) => {
  return (
    <Button
      active={isMarkBtnActive(editor, format)}
      format={format}
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
      title={title}
      style={{ color: "#64748B" }}
    >
      <Icon icon={format} />
    </Button>
  );
};

export default MarkButton;
