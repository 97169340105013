import React from "react";
import { Text } from "slate";

const isEmptyTextNode = (node) => {
  if (Text.isText(node)) {
    return !node.text.trim();
  }
  return false;
};

const Title = (props) => {
  const { element, attributes, children, customProps } = props;
  const isEmpty =
    !customProps?.readOnly && isEmptyTextNode(element?.children[0]) ? "empty" : "";
  return (
    <div {...attributes} placeholder="Title" className={`content-editable ${isEmpty}`} style={{ fontWeight: "bold", fontSize: "20px" }}>
      {children}
    </div>
  );
};

export default Title;
