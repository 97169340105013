import { useMemo } from "react";
import { isBlockActive, toggleBlock } from "../../../utils/SlateUtilityFunctions";
import CustomSelectTool from "./CustomSelectTool"
import Icon from "../../../common/Icon";

function Label({ icon, label }) {
    return <span className="menuOptions"><Icon icon={icon} /> {label}</span>
}

const alignmentOptions = [
    {
        id: 17,
        value: "alignLeft",
        type: "block",
        title: <Label icon="alignLeft" label="Left Align" />,
        label: <Icon icon="alignLeft" />,
    },
    {
        id: 18,
        value: "alignCenter",
        type: "block",
        title: <Label icon="alignCenter" label="Center Align" />,
        label: <Icon icon="alignCenter" />,
    },
    {
        id: 19,
        value: "alignRight",
        type: "block",
        title: <Label icon="alignRight" label="Right Align" />,
        label: <Icon icon="alignRight" />,
    }
];

function SelectAlignment({ editor, classes, closeMainPopup }) {
    const selected = useMemo(() => {
        return alignmentOptions.find(t => isBlockActive(editor, t.value))
    }, [alignmentOptions, isBlockActive, editor]);

    const onChange = (format, option) => {
        if (option.type === "block") {
            toggleBlock(editor, format);
            closeMainPopup()
        }
    }

    return (
        <CustomSelectTool
            options={alignmentOptions}
            editor={editor}
            onChange={onChange}
            value={selected?.value || "alignLeft"}
            classes={classes}
        />
    )
}

export default SelectAlignment;