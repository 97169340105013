import React from "react";
import {
  MdFormatQuote,
  MdAdd,
  MdArrowForward,
  MdOutlinePermMedia,
} from "react-icons/md";
import {
  BsCameraVideoFill,
  BsArrowBarRight,
  BsArrowBarLeft,
} from "react-icons/bs";
import { FaSuperscript, FaSubscript } from "react-icons/fa";
import { FcAddRow, FcAddColumn } from "react-icons/fc";
import {
  AiFillEdit,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowRight,
  AiOutlineDelete,
  AiFillTag,
  AiOutlineUpload,
  AiOutlineArrowsAlt,
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowLeft,
  AiFillHtml5,
} from "react-icons/ai";
import { SiLatex } from "react-icons/si";
import { RiDeleteColumn, RiDeleteRow } from "react-icons/ri";
import {
  FontFamilyIcon,
  FontSizeIcon,
  StrikethroughIcon,
  MoreHorizontal,
  UploadImage,
  LeftArrow,
  RightArrow,
  CheckListButton,
  CheckListButtonActive,
  ExcelIcon,
  CsvIcon,
  CloseIcon,
  SearchIcon,
  ExpandIcon,
  Text,
  TextAreaIcon,
  Phone,
  BriefCase,
  Bank,
  CalendarTick,
  DollarSquare,
  Checkbox,
  Description,
  RadioButtonIcon,
  CheckedIcon,
  UncheckedIcon,
  InfinityIcon,
  ResetIcon,
  GridAddSectionIcon,
} from "./iconslist";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GridOnIcon from "@mui/icons-material/GridOn";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";

import {
  AccordionTextFormatIcon,
  BoldTextFormatIcon,
  BulletedListTextFormatIcon,
  ButtonElementIcon,
  CarouselElementIcon,
  CheckListTextFormatIcon,
  ColorBoxElementIcon,
  DividerElementIcon,
  DocUploadElementIcon,
  EmbedElementIcon,
  EmojiElementIcon,
  FormElementIcon,
  GridElementIcon,
  H1,
  H2,
  H3,
  ImageElementIcon,
  ItalicTextFormatIcon,
  LinkIconV2,
  OrderedListTextFormatIcon,
  SignatureElementIcon,
  TableElementIcon,
  TopBannerElementIcon,
  UnderlineTextFormatIcon,
  VideoElementIcon,
  LeftAlignTextFormat,
  CenterAlignTextFormat,
  RightAlignTextFormat,
  JustifyTextFormat,
  FreeGridElement,
  AppHeaderElement,
  CodeElementIcon,
} from "./iconListV2";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SettingsIcon from "../assets/svg/SettingsIcon";
import UndoIcon from "../assets/svg/UndoIcon";
import RedoIcon from "../assets/svg/RedoIcon";
import TextIcon from "../assets/svg/TextIcon";
import AddElementIcon from "../assets/svg/AddElementIcon";
import AddTemplateIcon from "../assets/svg/AddTemplateIcon";
import OpenLinkIcon from "../assets/svg/OpenLinkIcon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BrainIcon } from "../assets/svg/BrainIcon";
import DocsIcon from "../assets/svg/DocsIcon";
import UserIcon from "../assets/svg/UserIcon";
import HashtagIcon from "../assets/svg/HashtagIcon";
import SelectRoundedIcon from "../assets/svg/SelectRoundedIcon";
import SortByIcon from "../assets/svg/SortByIcon";
import TickOutlined from "../assets/svg/TickOutlined";
import CalenderIconTick from "../assets/svg/CalenderIconTick";
import PlusIcon from "../assets/svg/PlusIcon";
import EyeSlash from "../assets/svg/EyeSlash";
import ArrowUpIcon from "../assets/svg/ArrowUpIcon";
import ArrowDownIcon from "../assets/svg/ArrowDownIcon";
import DuplicateIcon from "../assets/svg/DuplicateIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import TrashIcon from "../assets/svg/TrashCanIcon";
import DataTableIcon from "../assets/svg/DataTableIcon";
import ChervDown from "../assets/svg/ChervDown";
import ChervUp from "../assets/svg/ChervUp";

const HeadingIcon = ({ variant }) => {
  return <div style={{ color: "#64748B" }}>{variant}</div>;
};

const iconList = {
  fontFamily: <FontFamilyIcon size={20} />,
  fontSize: <FontSizeIcon size={20} />,
  // bold: <MdFormatBold size={20} />,
  bold: <BoldTextFormatIcon size={20} />,
  // italic: <MdFormatItalic size={20} />,
  italic: <ItalicTextFormatIcon size={20} />,
  // strikethrough: <MdStrikethroughS size={20} />,
  strikethrough: <StrikethroughIcon size={20} />,
  // underline: <MdFormatUnderlined size={20} />,
  underline: <UnderlineTextFormatIcon size={20} />,
  headingOne: <H1 />,
  headingTwo: <H2 size={18} fill="#64748B" />,
  headingThree: <H3 size={18} fill="#64748B" />,
  headingFour: <HeadingIcon variant={"H4"} />,
  headingFive: <HeadingIcon variant={"H5"} />,
  headingSix: <HeadingIcon variant={"H6"} />,
  paragraphOne: <HeadingIcon variant={"P1"} />,
  paragraphTwo: <HeadingIcon variant={"P2"} />,
  paragraphThree: <HeadingIcon variant={"P3"} />,
  blockquote: (
    <MdFormatQuote size={20} fill="#64748B" className="blockQuoteSvg" />
  ),
  doublequote: <MdFormatQuote size={20} fill="#64748B" />,
  superscript: <FaSuperscript size={15} fill="#64748B" />,
  subscript: <FaSubscript size={15} fill="#64748B" />,
  alignLeft: <LeftAlignTextFormat size={18} fill="#64748B" />,
  alignCenter: <CenterAlignTextFormat size={18} fill="#64748B" />,
  alignRight: <RightAlignTextFormat size={18} fill="#64748B" />,
  alignJustify: <JustifyTextFormat size={18} fill="#64748B" />,
  orderedList: <OrderedListTextFormatIcon size={18} fill="#64748B" />,
  unorderedList: <BulletedListTextFormatIcon size={18} fill="#64748B" />,
  // link: <MdInsertLink size={20} />,
  link: <LinkIconV2 size={20} />,
  // image: <MdImage size={20} />,
  image: <ImageElementIcon size={20} />,
  // video: <MdVideoLibrary size={20} />,
  video: <VideoElementIcon />,
  add: <MdAdd size={20} />,
  // table: <AiOutlineTable size={20} />,
  table: <TableElementIcon size={20} />,
  insertRowBelow: <AiOutlineInsertRowBelow size={25} />,
  insertColumnRight: <AiOutlineInsertRowRight size={25} />,
  insertColumnLeft: <AiOutlineInsertRowLeft size={25} />,
  insertRowAbove: <AiOutlineInsertRowAbove size={25} />,
  trashCan: <AiOutlineDelete size={25} />,
  addId: <AiFillTag size={20} />,
  upload: <AiOutlineUpload size={20} />,
  equation: <SiLatex size={20} />,
  resize: <AiOutlineArrowsAlt size={20} />,
  videoPlayer: <BsCameraVideoFill size={20} />,
  insertHtml: <AiFillHtml5 size={20} />,
  arrowRight: <MdArrowForward size={35} />,
  pen: <AiFillEdit size={20} />,
  emoji: <EmojiElementIcon size={20} fill="#64748B" />,
  grid: <GridElementIcon />,
  accordion: <AccordionTextFormatIcon />,
  signature: <SignatureElementIcon />,
  button: <ButtonElementIcon />,
  carousel: <CarouselElementIcon />,
  form: <FormElementIcon />,
  tableCellResizeLeft: <BsArrowBarRight size={20} />,
  tableCellResizeRight: <BsArrowBarLeft size={20} />,
  deleteColumn: <RiDeleteColumn size={20} />,
  addColumn: <FcAddColumn size={20} />,
  deleteRow: <RiDeleteRow size={20} />,
  addRow: <FcAddRow size={20} />,
  "check-list-item": <CheckListTextFormatIcon size={20} />,
  embed: <EmbedElementIcon />,
  topbanner: <TopBannerElementIcon size={20} fill="#64748B" />,
  fileUpload: <UploadImage />,
  media: <MdOutlinePermMedia size={20} />,
  accordionArrow: <ArrowRightIcon size={20} />,
  accordionArrowDown: <ArrowDropDownIcon size={20} />,
  appHeader: <AppHeaderElement />,
  moreHorizantal: <MoreHorizontal />,
  docsUpload: <DocUploadElementIcon />,
  colorbox: <ColorBoxElementIcon style={{ fill: "#64748B" }} size={20} />,
  moreVertical: <MoreVertRoundedIcon />,
  leftArrow: <LeftArrow />,
  rightArrow: <RightArrow />,
  checkListButton: <CheckListButton />,
  checkListButtonActive: <CheckListButtonActive />,
  excelIcon: <ExcelIcon />,
  csvIcon: <CsvIcon />,
  divider: <DividerElementIcon />,
  SearchIcon: <SearchIcon />,
  expandIcon: <ExpandIcon />,
  closeIcon: <CloseIcon />,
  embedScript: (
    <CodeElementIcon
      className="fg-op-ico"
      size={20}
      style={{ fill: "#64748B" }}
    />
  ),
  freegrid: <FreeGridElement />,
  text: <Text stroke={"#64748B"} />,
  calenderNewIcon: <CalenderIconTick />,
  textArea: <TextAreaIcon />,
  phone: <Phone />,
  briefCase: <BriefCase />,
  bank: <Bank />,
  calendarTick: <CalendarTick />,
  dollarSquare: <DollarSquare />,
  description: <Description />,
  radioButton: <RadioButtonIcon />,
  checkbox: <Checkbox />,
  mail: (
    <EmailRoundedIcon sx={{ width: "18px", height: "16px", fill: "#000" }} />
  ),
  info: (
    <InfoOutlinedIcon
      sx={{
        width: "14px",
        height: "14px",
        paddingLeft: "5px",
        marginTop: "5px",
        fill: "#94A3B8",
      }}
    />
  ),
  checkedIcon: <CheckedIcon />,
  uncheckedIcon: <UncheckedIcon />,
  infinityIcon: <InfinityIcon />,
  resetIcon: <ResetIcon />,
  pagesSettings: <SettingsIcon />,
  undoIcon: <UndoIcon />,
  redoIcon: <RedoIcon />,
  textIcon: <TextIcon />,
  addElement: <AddElementIcon />,
  addTemplate: <AddTemplateIcon />,
  openLinkIcon: <OpenLinkIcon />,
  dataView: <GridOnIcon sx={{ fill: "#64748B" }} />,
  editIcon: <EditIcon />,
  deleteIcon: <DeleteIcon />,
  brain: <BrainIcon />,
  docsIcon: <DocsIcon />,
  userIcon: <UserIcon />,
  shieldNoAccess: <GppBadOutlinedIcon />,
  hashtagIcon: <HashtagIcon />,
  selectRounded: <SelectRoundedIcon />,
  sortBy: <SortByIcon />,
  addRounded: <GridAddSectionIcon />,
  tickOutline: <TickOutlined />,
  plusIcon: <PlusIcon />,
  eyeSlash: <EyeSlash />,
  eyeIcon: <EyeIcon />,
  arrowUp: <ArrowUpIcon />,
  arrowDown: <ArrowDownIcon />,
  duplicateIcon: <DuplicateIcon />,
  trashIcon: <TrashIcon />,
  dataTable: <DataTableIcon />,
  chervUp: <ChervUp />,
  chervDown: <ChervDown />,
};

export const icons = {
  ...iconList,
};

const Icon = (props) => {
  const { icon } = props;
  return iconList[icon] ? iconList[icon] : <div>dummy {icon}</div>;
};

export default Icon;
