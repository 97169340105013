import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Style
import Style from "./style";

// icons
import { ChevronLeft } from "@mui/icons-material";
import { EditIcon } from "../icons";
import ColorPickerButton from "../../common/ColorPickerButton";
import { getContrastColor } from "../../utils/helper";

const ColorTheme = (props) => {
  const { className, colors, onColorChange, openDefault } = props;

  const { primaryColors, otherColors = [] } = colors || {};

  // State
  const [customizeTheme, setCustomizeTheme] = React.useState(false);
  const [buttonType, setButtonType] = React.useState("regular");

  const customTheme = [
    {
      title: "Text & Line Colors",
      data: [
        {
          title: "Headings",
          color: "#ccc",
        },
        {
          title: "Subheading text",
          color: "#ccc",
        },
        {
          title: "Body text",
          color: "#ccc",
        },
        {
          title: "Subtext",
          color: "#ccc",
        },
        {
          title: "Lines & dividers",
          color: "#ccc",
        },
      ],
    },
    {
      title: "Background Colors",
      data: [
        {
          title: "Primary background color",
          color: "#ccc",
        },
        {
          title: "Secondary background color",
          color: "#ccc",
        },
      ],
    },
  ];

  const buttonData = [
    {
      title: "Button background",
      color: "#ccc",
    },
    {
      title: "Button text",
      color: "#ccc",
    },
    {
      title: "Button border",
      color: "#ccc",
    },
  ];

  //button
  const handleButtonType = (event, buttonType) => {
    if (buttonType !== null) {
      setButtonType(buttonType);
    }
  };

  return (
    <Grid className={className}>
      {!customizeTheme ? (
        <Accordion className="settingAccordion" defaultExpanded={openDefault}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Color Theme
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ pb: 1 }} className="fs-12 fw-600">
              Primary Color
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ pb: 2 }}
              className="primaryColorBoxes"
              wrap="nowrap"
            >
              {primaryColors?.map((color, i) => {
                const textColor = getContrastColor(color);

                return (
                  <Grid item xs={6} key={i}>
                    <ColorPickerButton
                      onSave={(selectedColor) => {
                        onColorChange(selectedColor, i);
                      }}
                      hideThemeColors={true}
                      hideGradient={true}
                    >
                      <Grid
                        className="pmBox"
                        style={{ backgroundColor: color }}
                      >
                        <IconButton className="editBtn">
                          <EditIcon />
                        </IconButton>
                        <Typography
                          variant="body2"
                          className="fs-12 valyeText"
                          style={{ color: textColor }}
                        >
                          {color}
                        </Typography>
                      </Grid>
                    </ColorPickerButton>
                  </Grid>
                );
              })}
            </Grid>
            <Typography variant="body2" sx={{ pb: 1 }} className="fs-12 fw-600">
              Secondary Colors
            </Typography>
            <Grid
              container
              className="secondaryColorBoxes"
              wrap="nowrap"
              sx={{ mb: 1 }}
            >
              {otherColors?.slice(0, 4)?.map((color, i) => {
                const noOfPrimaryColors = 2;
                const colorIndex = noOfPrimaryColors + i;
                const textColor = getContrastColor(color);

                return (
                  <Grid item xs={6} key={i}>
                    <ColorPickerButton
                      onSave={(selectedColor) => {
                        onColorChange(selectedColor, colorIndex);
                      }}
                      hideThemeColors={true}
                      hideGradient={true}
                    >
                      <Grid
                        className="sdBox"
                        style={{ backgroundColor: color }}
                      >
                        <IconButton className="editBtn">
                          <EditIcon />
                        </IconButton>
                        <Typography
                          variant="body2"
                          className="fs-12 valyeText"
                          style={{ color: textColor }}
                        >
                          {color}
                        </Typography>
                      </Grid>
                    </ColorPickerButton>
                  </Grid>
                );
              })}
            </Grid>

            {/* <Link
              className="pointer fs-12 fw-500"
              onClick={() => setCustomizeTheme(true)}
            >
              View Advance Settings
            </Link> */}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Grid className="sectionDetail">
          {/* Customize Theme settings */}
          <Typography variant="body1" className="sectionTitle flexAlign">
            <IconButton onClick={() => setCustomizeTheme(false)}>
              <ChevronLeft />
            </IconButton>
            Customize Theme settings
          </Typography>

          {customTheme.map((item, index) => (
            <Grid className="settingsSection" key={index}>
              <Typography
                variant="body1"
                className="sectionTitle"
                sx={{ pb: 1 }}
              >
                {item.title}
              </Typography>

              {item.data.map((childitem, childIndex) => (
                <Grid
                  container
                  justifyContent={"space-between"}
                  sx={{ pt: 2 }}
                  key={childIndex}
                >
                  <Typography variant="body1" className="fs-14">
                    {childitem.title}
                  </Typography>
                  <Grid
                    className="xsColorBox"
                    sx={{ background: childitem.color }}
                  ></Grid>
                </Grid>
              ))}
            </Grid>
          ))}

          <Grid className="settingsSection">
            <Typography variant="body1" className="sectionTitle" sx={{ pb: 1 }}>
              Button Colors
            </Typography>

            <ToggleButtonGroup
              sx={{ mt: 2 }}
              className="toggleType"
              value={buttonType}
              exclusive
              onChange={handleButtonType}
            >
              <ToggleButton value="regular" aria-label="regulard">
                Regular
              </ToggleButton>
              <ToggleButton value="hover" aria-label="hover">
                Hover
              </ToggleButton>
            </ToggleButtonGroup>

            <Divider sx={{ mt: 2, mb: 1 }} />

            <RadioGroup
              sx={{ mt: 1 }}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="primary"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="primary"
                control={<Radio />}
                label={
                  <Typography variant="body1" className="fs-14">
                    Primary button
                  </Typography>
                }
              />
              <FormControlLabel
                value="secondary"
                control={<Radio />}
                label={
                  <Typography variant="body1" className="fs-14">
                    Secondary button
                  </Typography>
                }
              />
            </RadioGroup>

            {buttonData.map((item, Index) => (
              <Grid
                container
                justifyContent={"space-between"}
                sx={{ pt: 2 }}
                key={Index}
              >
                <Typography variant="body1" className="fs-14">
                  {item.title}
                </Typography>
                <Grid
                  className="xsColorBox"
                  sx={{ background: item.color }}
                ></Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default styled(ColorTheme)(Style);
