import React from "react";
import { Transforms, Node } from "slate";
import { Box } from "@mui/material";
import { StyleContent } from "../../../StyleBuilder";
import appHeaderStyle from "../../../StyleBuilder/appHeaderStyle";

const AppHeaderSettings = (props) => {
  const { editor, path, customProps, onClose } = props;
  const item_path = path?.split("|").map((m) => parseInt(m));
  const element_path = [...item_path, 0];
  const element = Node.get(editor, element_path);

  const onChange = (data) => {
    const updated_props = {
      ...element,
      ...data,
      field_type: data?.element,
    };
    delete updated_props.children;
    Transforms.setNodes(
      editor,
      {
        ...updated_props,
      },
      {
        at: element_path,
      }
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Box component={"div"} className="item-w">
      {appHeaderStyle?.map((m, i) => {
        return (
          <StyleContent
            key={`tab_${m.value}_$${i}`}
            renderTabs={appHeaderStyle}
            value={m.value}
            element={element}
            onChange={onChange}
            customProps={customProps}
            handleClose={handleClose}
          />
        );
      })}
    </Box>
  );
};

export default AppHeaderSettings;
