import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";

function MainThemeProvider({ children, userSelectedTheme, theme = {} }) {
  const themeVars = extendTheme(userSelectedTheme?.theme?.cssVars || {});

  return (
    <CssVarsProvider theme={{ ...themeVars, ...theme }}>
      {children}
    </CssVarsProvider>
  );
}

export default MainThemeProvider;
