export const sizeMap = {
  small: "12px",
  normal: "16px",
  medium: "28px",
  huge: "40px",
};

export const fontFamilyMap = {
  PoppinsRegular: "PoppinsRegular",
  PoppinsBold: "PoppinsBold",
  sans: 'Helvetica, Arial, "sans serif"',
  serif: 'Georgia, "Times New Roaman", serif',
  monospace: 'Monaco, "Courier New", monospace',
  roboto: "Roboto, sans-serif",
  qwitcher: '"Qwitcher Grypen", cursive',
  garamond: '"EB Garamond", serif',
  anton: "Anton, sans-serif",
  dmserif: '"DM Serif Text", serif',
  inter: "Inter, sans-serif",
  libre: '"Libre Baskerville", serif',
  montserrat: "Montserrat, sans-serif",
  opensans: '"Open Sans", sans-serif',
  publicsans: '"Public Sans", sans-serif',
  raleway: "Raleway, sans-serif",
  spacemono: '"Space Mono", sans-serif',
  bulgarian: '"Bulgarian Garamond", monospace',
  impact: "Impact, serif",
  redacted: '"Redacted Script", cursive',
  greatVibes: '"Great Vibes", cursive',
  zeyada: "Zeyada, cursive",
  allura: "Allura, cursive",
  pinyon: '"Pinyon Script", cursive',
  muellerhoff: '"Herr Von Muellerhoff", cursive',
  dawning: '"Dawning of a New Day", cursive',
  // New Font Added for Type Signature
  comingsoon: '"Coming Soon", cursive',
  dancingScript: '"Dancing Script", cursive',
  engagement: "Engagement, cursive",
  gaegu: "Gaegu, cursive",
  ingridDarling: '"Ingrid Darling", cursive',
  kitaOne: "Times",
  laBelleAurore: '"La Belle Aurore", cursive',
  lobster: "Lobster, cursive",
  meaCulpa: '"Mea Culpa", cursive',
  meddon: "Meddon, cursive",
  merriWeather: "Merriweather, serif",
  theGirlNextDoor: '"The Girl Next Door", cursive',
};

export const fontOptions = Object.keys(fontFamilyMap).map((m) => {
  return {
    text: fontFamilyMap[m],
    value: m,
  };
});

// get last 12 fonts for signature text fonts
export const signedTextFonts = Object.keys(fontFamilyMap)
  .slice(-12)
  .map((m) => {
    return {
      text: fontFamilyMap[m],
      value: m,
    };
  });

export const headingMap = {
  headingOne: "32px",
  headingTwo: "24px",
  headingThree: "19px",
  headingFour: "16px",
  headingFive: "13px",
  headingSix: "11px",
};

export const fontWeightOptions = [
  {
    text: "Bold",
    value: "500",
  },
  {
    text: "Bolder",
    value: "600",
  },
];
