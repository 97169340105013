import React from "react";
import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";
import Icon from "../../common/Icon";
import { useEditorContext } from "../../hooks/useMouseMove";

const SearchAttachment = (props) => {
    const { attributes, element, children, customProps } = props;
    const { metadata } = customProps;
    const { title, type } = element; 
    const { theme } = useEditorContext();
    const label = Boolean(title?.trim()) ? title : 'Untitled';
    const handleClick = () => {
        if (metadata && metadata?.actionHandler) {
            metadata?.actionHandler(type, element);
        }
    }

    return <Box
        component={"div"}
        className={"attachment-wrpr-ev2"}
        {...attributes}
        contentEditable={false}
        style={{ display: "block" }}
    >
        <Card
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                width: "fit-content",
                maxWidth: '250px',
                padding: "0px 10px",
                boxShadow: "none",
                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder} !important`,
                borderRadius: "7px !important",
                background: `${theme?.palette?.containers?.slashBrainCardBg} !important`,
                cursor: 'pointer',
                margin: '4px 0px',
                lineHeight: 1.43,
                "&.MuiPaper-root.MuiPaper-rounded":{
                    borderRadius: "7px !important",
                    paddingTop: '0px !important'
                },
                "& *::selection": {
                    background: "#000",
                },
            }}
            onClick={handleClick}
        >
            <CardMedia
                sx={{
                    "& svg": {
                        '& path': {
                            stroke: theme?.palette?.text?.slashBrainText
                        }
                    },
                }}
            >
                <Icon icon="docsIcon" />
            </CardMedia>
            <CardContent
                sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "3px 0px 3px 5px",
                    textDecoration: "none",
                    flexDirection: "column",
                    color: "#2563EB",
                    '&.MuiCardContent-root:last-child': {
                        paddingBottom: '3px'
                    }
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "500", 
                        background: theme?.palette?.text?.slashBrainText,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: 'transparent',
                        fontSize: "14px", 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        wordBreak: "break-word",
                        maxWidth: '210px',
                        lineHeight: 1.43,
                        "&::selection": {
                            WebkitTextFillColor: "#000",
                        },
                    }}
                    component={"div"}
                    variant="subtitle1"
                >
                    {label}
                    {children}
                </Typography>
            </CardContent>
        </Card>
    </Box>
};

export default SearchAttachment;
