import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Editable, Slate, ReactEditor } from "slate-react";
import { createEditor, Transforms, Editor } from "slate";

import withCommon from "./hooks/withCommon";
import {
  getBlock,
  getMarked,
  serializeMentions,
} from "./utils/chatEditor/SlateUtilityFunctions";
import MiniTextFormat from "./Toolbar/PopupTool/MiniTextFormat";
import { commands, mentionsEvent } from "./utils/events";
import { insertEmoji } from "./utils/emoji";
import { draftToSlate } from "./utils/draftToSlate";
import MentionsPopup from "./common/MentionsPopup";
import { serializeToText } from "./utils/serializeToText";
import useMentions from "./hooks/useMentions";
import Shorthands from "./common/Shorthands";
import usePopupStyle from "./Toolbar/PopupTool/PopupToolStyle";
import { EditorProvider } from "./hooks/useMouseMove";
import decorators from "./utils/Decorators";
import { useDebouncedCallback } from "use-debounce";

const ChatEditor = forwardRef((props, ref) => {
  const {
    id,
    theme,
    content,
    readOnly,
    otherProps,
    needLayout = false,
    toolBar = true,
    onSave,
    onsubmit,
    onBlur = () => {},
    isMobile = false,
    debounceTimeOut = 10,
  } = props;
  const classes = usePopupStyle(theme);
  const convertedContent = draftToSlate({
    data:
      content && content?.length > 0
        ? content
        : [{ type: "paragraph", children: [{ text: "" }] }],
  });
  const [value] = useState(convertedContent);
  const debouncedValue = useRef(value);

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      const { value: strVal, ...restVal } = getOnSaveData(value);
      onSave(strVal, restVal);
    },
    // delay in ms
    debounceTimeOut
  );

  const editor = useMemo(() => {
    return withCommon(createEditor(), { needLayout, isChatEditor: true });
  }, []);
  const isReadOnly = readOnly === "readonly";

  useImperativeHandle(ref, () => ({
    emojiClick: (emoji) => {
      if (editor) {
        ReactEditor.focus(editor);
        insertEmoji(editor, emoji?.native, editor.selection);
        ReactEditor.focus(editor);
      }
    },

    submitChat: () => {
      const { value: strVal, ...restVal } = getOnSaveData(
        debouncedValue?.current
      );
      onsubmit(false, { strVal, restVal });
    },

    // Focus enable
    enableFocus: () => {
      if (editor) {
        const position = {
          anchor: { path: [0], offset: 0 },
          focus: { path: [0], offset: 0 },
        };
        Transforms.select(editor, position);
        ReactEditor.focus(editor);
      }
    },

    clearAll: (content = null, clear = true) => {
      if (!editor) return;
      try {
        if (clear) {
          while (editor.children.length > 0) {
            Transforms.removeNodes(editor, { at: [0] });
          }
        }
        const newValue = draftToSlate({ data: content });
        debounced(newValue);

        // setTimeout(() => {
        if (editor.children.length === 0) {
          Transforms.insertNodes(editor, newValue);
        }
        Transforms.select(editor, Editor.end(editor, []));
        ReactEditor.focus(editor);
        //   }, 300);
      } catch {
        console.log("error:");
      }
    },
  }));

  const getOnSaveData = (val) => {
    const text = serializeToText(val);
    const mentions = serializeMentions(val);
    const title = val?.find((f) => f.type === "title");
    return {
      value: JSON.stringify(val),
      text: text,
      mentions: mentions,
      title: serializeToText(title?.children) || "Untitled",
    };
  };

  const {
    CHARACTERS = [],
    hideTools,
    // needLayout = true,
  } = otherProps || {};

  const mentionsRef = useRef();

  const customProps = {
    ...(otherProps || {}),
    readOnly: isReadOnly,
    editorPlaceholder: "Write Something",
    page_id: 1,
  };

  const [mentions, setMentions] = useMentions({
    editor,
    selection: editor?.selection,
  });

  const { search, target, index } = mentions;
  let { type } = mentions;
  if (type && type === "elements" && hideTools.indexOf("slash") > -1) {
    type = null;
  }
  const chars = type
    ? Shorthands[type]({ ...mentions, CHARACTERS, hideTools: hideTools })
    : [];

  const Leaf = ({ attributes, children, leaf }) => {
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>;
  };

  const handleEditorChange = (newValue) => {
    debounced(newValue);
    debouncedValue.current = newValue;
  };

  const Element = (props) => {
    return getBlock(props);
  };
  const renderElement = useCallback((props) => {
    return <Element {...props} customProps={customProps} />;
  }, []);
  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} customProps={customProps} />;
  }, []);

  const onKeyDown = useCallback(
    (event) => {
      const isMetaKey =
        event.metaKey && event.keyCode >= 65 && event.keyCode <= 90;
      const isCtrlKey = event.ctrlKey || isMetaKey;
      if (target && chars.length > 0 && !isCtrlKey) {
        mentionsEvent({
          event,
          mentions,
          setMentions,
          chars,
          target,
          editor,
          type,
          mentionsRef,
        });
      } else if (isCtrlKey) {
        commands({
          event,
          editor,
        });
      } else if (event.key === "Enter" && !isMobile) {
        const isEmpty =
          debouncedValue?.current.length === 1 &&
          debouncedValue?.current[0].type === "paragraph" &&
          debouncedValue?.current[0].children.length === 1 &&
          debouncedValue?.current[0].children[0].text === "";
        if (isEmpty) {
          event.preventDefault();
          return;
        }
        if (!event.shiftKey) {
          const { value: strVal, ...restVal } = getOnSaveData(
            debouncedValue?.current
          );
          onsubmit(false, { strVal, restVal });
          event.preventDefault();
        }
      }
    },
    [chars, editor, target, mentions, setMentions, search, type, mentionsRef]
  );

  const handleClose = () => {};

  const handleBlur = () => {
    const { value: strVal, ...restVal } = getOnSaveData(
      debouncedValue?.current
    );
    onBlur({ strVal, restVal });
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.startsWith("image/")) {
        event.preventDefault(); // Prevent the default paste behavior
        return; // Exit early to keep the editor empty
      }
    }
  };

  return (
    <EditorProvider theme={theme} editor={editor}>
      <Slate
        key={id}
        editor={editor}
        initialValue={debouncedValue?.current}
        onChange={handleEditorChange}
      >
        {toolBar && (
          <MiniTextFormat
            classes={classes}
            editor={editor}
            closeMainPopup={handleClose}
          />
        )}
        <Editable
          className={"chatEditorRoot"}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          decorate={decorators}
          placeholder="Start typing ..."
          spellCheck
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          onPaste={handlePaste}
        />
        {!readOnly ? (
          <MentionsPopup
            ref={mentionsRef}
            mentions={mentions}
            setMentions={setMentions}
            editor={editor}
            target={target}
            index={index}
            chars={chars}
            type={type}
            theme={theme}
          />
        ) : null}
      </Slate>
    </EditorProvider>
  );
});

ChatEditor.displayName = "ChatEditor";

export default ChatEditor;
