import React, { createContext, useContext, useEffect, useState } from "react";
import { Transforms, Node } from "slate";
import { PROPERTY_DEFAULTS } from "../Layouts/Options/Constants";
import multiSortRows from "../Utils/multiSortRows";
import globalSearch from "../Utils/globalSearch";

// Data View context
const DataViewContext = createContext();
export const useDataView = () => useContext(DataViewContext);

const DEFAULT_PROPERTIES = [
  { key: "column1", label: "Name", dataType: "text" },
  {
    key: "column2",
    label: "Status",
    dataType: "select",
    options: ["Active", "Inactive"],
  },
  { key: "column3", label: "Agree?", dataType: "checkbox" },
];

// Combined provider
export const DataViewProvider = ({ children, ...props }) => {
  const { data: initialData, path, editor, users: peoples } = props;
  const dataViewNode = Node.get(editor, path);
  const [layouts, setLayouts] = useState(initialData?.layouts || []);
  const [seletectedLayout, setSelectedLayout] = useState({
    ...(layouts[0] || {}),
  });
  const [layoutType, setLayoutType] = useState(
    seletectedLayout?.type || "table"
  );
  const [properties, setProperties] = useState(
    initialData?.properties || [...DEFAULT_PROPERTIES]
  );
  const [sort, setSort] = useState(seletectedLayout?.sort || []);
  const [filter, setFilter] = useState(seletectedLayout?.filter || []);
  const [rows, setRows] = useState(initialData?.rows || []);
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState("");
  const [users] = useState(peoples);

  // for undo and redo
  // minimal added for perforamnce issue avoid
  useEffect(() => {
    setRows(dataViewNode?.rows);
  }, [dataViewNode?.rows?.length]);

  useEffect(() => {
    setProperties(dataViewNode?.properties);
  }, [dataViewNode?.properties?.length]);

  // re-order when sort val changes
  useEffect(() => {
    if ((sort?.length > 0 || search) && rows?.length > 0) {
      const reOrderRows =
        sort?.length > 0
          ? multiSortRows(initialData?.rows, sort, properties)
          : [...initialData?.rows];
      setRows(globalSearch(reOrderRows, search));
    } else {
      // reset to default order
      setRows(globalSearch(initialData?.rows || [], search));
    }
  }, [sort, search]);

  const onAddProperty = (data, overrides = {}) => {
    try {
      const { type } = data;
      const key = `col_${new Date().getTime()}`;
      const newProperty = {
        ...(PROPERTY_DEFAULTS[type] || {}),
        ...overrides,
        key: key,
        type,
      };
      const updatedProperties = [...properties, { ...newProperty }];
      Transforms.setNodes(
        editor,
        {
          properties: [...updatedProperties],
        },
        { at: path }
      );
      setProperties([...updatedProperties]);
      return newProperty;
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateProperty = (data, isDelete = false) => {
    try {
      const { key } = data;
      let up = { ...data };
      const updatedProperties = properties?.map((m) => {
        if (m.key === key) {
          up = {
            ...m,
            ...data,
          };
          return up;
        }
        return m;
      });

      if (isDelete) {
        const deleteIndex = updatedProperties.findIndex((f) => f.key === key);
        updatedProperties.splice(deleteIndex, 1);
      }

      Transforms.setNodes(
        editor,
        {
          properties: [...updatedProperties],
        },
        { at: path }
      );
      setProperties([...updatedProperties]);
      return up;
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (rowIndex, rowData) => {
    try {
      const updatedRows = rows?.map((m) => {
        if (m?.id === rowIndex) {
          m = {
            ...m,
            ...rowData,
          };
        }
        return m;
      });
      Transforms.setNodes(
        editor,
        {
          rows: [...updatedRows],
        },
        { at: path }
      );
      setRows(updatedRows);
    } catch (err) {
      console.log(err);
    }
  };

  const onAddRow = () => {
    try {
      const newRow = properties?.reduce(
        (a, b) => {
          a[b.key] = "";
          return a;
        },
        { id: `row_${new Date().getTime()}` }
      );
      const updatedRows = [...rows, newRow];
      Transforms.setNodes(
        editor,
        {
          rows: [...updatedRows],
        },
        { at: path }
      );
      setRows(updatedRows);
    } catch (err) {
      console.log(err);
    }
  };

  const formatSort = (sorts = [], sortData, isDelete = false) => {
    let upSort = [];
    const isUpdate = sorts?.find((f) => f.key === sortData?.key);

    if (isUpdate) {
      // update if any
      upSort = sorts?.map((m) => {
        if (m.key === sortData.key) {
          // if update col
          if (sortData["newKey"]) {
            sortData["key"] = sortData["newKey"];
            delete sortData["newKey"];
          }
          return {
            ...sortData,
          };
        }
        return m;
      });
    } else {
      upSort = [...sorts, { ...sortData }];
    }

    // if no sort
    if (sorts?.length === 0 && !isDelete) {
      upSort = [{ ...sortData }];
    }

    // if delete
    if (isDelete) {
      const deleteIndex = upSort.findIndex((f) => f.key === sortData.key);
      upSort.splice(deleteIndex, 1);
    }

    return upSort;
  };

  const onUpdateSort = (sortData = {}, isDelete = false, deleteAll = false) => {
    try {
      let upSort = {};
      const updatedLayouts = layouts?.map((m, i) => {
        if (seletectedLayout?.key === m.key) {
          upSort = !deleteAll
            ? formatSort(m?.sort || [], sortData, isDelete)
            : [];
          return {
            ...m,
            sort: [...upSort],
          };
        }
        return m;
      });
      Transforms.setNodes(
        editor,
        {
          layouts: [...updatedLayouts],
        },
        { at: path }
      );
      setLayouts(updatedLayouts);
      setSort(upSort);
    } catch (err) {
      console.log(err);
    }
  };

  const onDeleteRows = () => {
    try {
      const updatedRows = [...rows].filter(
        (f) => selectedRows.includes(f.id) === false
      );
      Transforms.setNodes(
        editor,
        {
          rows: [...updatedRows],
        },
        { at: path }
      );
      setRows(updatedRows);
      setSelectedRows([]);
    } catch (err) {
      console.log(err);
    }
  };

  const onSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const value = {
    layoutType,
    setLayoutType,
    properties,
    setProperties,
    rows,
    setRows,
    onAddProperty,
    layouts,
    setLayouts,
    onUpdateProperty,
    onChange,
    onAddRow,
    users: users,
    onUpdateSort,
    sort,
    filter,
    setSelectedLayout,
    setFilter,
    selectedRows,
    setSelectedRows,
    onDeleteRows,
    search,
    onSearch,
  };

  return (
    <DataViewContext.Provider value={value}>
      {children}
    </DataViewContext.Provider>
  );
};
