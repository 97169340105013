import { Box, CircularProgress, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Icon from "../../common/Icon";
import { SearchIcon } from "../../common/iconslist";

const SearchAndDocList = ({ mapData, isLoading, lastDocRef, onSearchChange, handleClick, theme, handleClose }) => {
    const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;
    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '9px 16px 8px 16px' }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 600, color: theme?.palette?.primary?.main }}>
                    Brains
                </Typography>
                {!isMobile ?
                    <IconButton
                        onClick={(e) => { e.stopPropagation(); handleClose() }}
                        sx={{
                            borderRadius: "6px",
                            backgroundColor: theme?.palette.type === 'dark' ? theme?.palette?.editor?.sectionSettingIconHover : theme?.palette?.editor?.sectionSettingIconHover,
                            padding: "3px",
                            "&:hover": { backgroundColor: theme?.palette?.editor?.sectionSettingIconHover, background: theme?.palette?.editor?.sectionSettingIconHover },
                            '& svg': {
                                '& path': {
                                    stroke: theme?.palette?.editor?.closeButtonSvgStroke
                                }
                            }
                        }}
                    >
                        <Icon icon='closeIcon' />
                    </IconButton>
                    : null
                }
            </Box>
            <Box sx={{ padding: '0px 16px' }}>
                <Divider sx={{ borderColor: theme?.palette?.primary?.slashBrainBorder }} />
            </Box>
            <Box sx={{ padding: '12px 16px' }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search here..."
                    size="small"
                    onChange={(e) => onSearchChange(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        sx: {
                            color: theme?.palette?.editor?.textColor,
                            fontSize: '14px',
                        },
                    }}
                    sx={{
                        borderRadius: "8px",
                        fontSize: '14px',
                        color: theme?.palette?.editor?.textColor,
                        "& .MuiOutlinedInput-root": {
                            color: `${theme?.palette?.editor?.textColor} !important`,
                            boxShadow: theme?.palette?.shadows?.shadow12,
                            backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
                            fontSize: '14px',
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "8px",
                                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                                borderColor: theme?.palette?.primary?.slashBrainBorder,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                color: theme?.palette?.editor?.textColor,
                                borderRadius: "8px",
                                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                                borderColor: `${theme?.palette?.primary?.slashBrainBorder} !important`,
                            },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            color: theme?.palette?.editor?.textColor,
                            borderRadius: "8px",
                            border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                            borderColor: theme?.palette?.primary?.slashBrainBorder,
                            fontSize: '14px',
                        },
                        '& .MuiInputBase-root': {
                            color: theme?.palette?.editor?.textColor,
                            borderRadius: '8px',
                            fontSize: '14px'
                        },
                        "& svg": {
                            width: '16px',
                            height: '16px',
                            "& path": { stroke: theme?.palette?.text?.text6 },
                        },
                    }}
                />
            </Box>
            <Grid
                container
                gap={1}
                justifyContent={"center"}
                sx={{
                    position: 'relative',
                    maxHeight: '400px',
                    minWidth: "275px",
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0px 16px 8px',
                    marginBottom: '20px',
                    scrollbarWidth: "thin",
                    scrollbarColor: `${theme?.palette?.editor?.brainPopupScroll} transparent`,
                    "::-webkit-scrollbar": {
                        width: "3px !important",
                        height: "3px !important",
                        borderRadius: "10px !important",
                    },
                    "::-webkit-scrollbar-thumb": {
                        backgroundColor: theme?.palette?.editor?.brainPopupScroll,
                        borderRadius: "10px !important",
                        width: "3px !important",
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: theme?.palette?.editor?.brainPopupScroll,
                        width: "3px !important",
                    },
                    "::-webkit-scrollbar-track": {
                        background: "transparent",
                        borderRadius: "10px !important",
                        width: "3px !important",
                    },
                }}
            >
                {mapData?.map((doc, index) => {
                    const title = doc?.title?.trim() === "" ? 'Untitled' : doc?.title;
                    return (
                        <Grid
                            item
                            xs={12}
                            key={doc.id}
                            ref={index === mapData.length - 1 ? lastDocRef : null}
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.slashBrainBorder}`,
                                borderRadius: '8px',
                                padding: '6px 10px',
                                cursor: 'pointer',
                                display: mapData?.length !== 0 ? 'flex' : 'none',
                                backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
                                '@media only screen and (min-width: 350px) and (max-width: 599px)': {
                                    overflow: 'hidden',
                                }
                            }}
                            onClick={() => handleClick(doc)}
                        >
                            <Grid container alignItems="center" justifyContent={'space-between'} sx={{ flexWrap: 'nowrap' }} gap={1}>
                                <Grid item sx={{ maxWidth: 'calc(100% - 45px)' }}>
                                    <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }} gap={1}>
                                        <Grid item sx={{ marginTop: '2px', '& svg': { '& path': { stroke: theme?.palette?.primary?.slashBrainIcon } } }}>
                                            <Icon icon="docsIcon" />
                                        </Grid>
                                        <Grid item sx={{ maxWidth: '100%' }}>
                                            <Typography sx={{
                                                fontSize: "12px", fontWeight: 500,
                                                color: theme?.palette?.primary?.main,
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                wordBreak: "break-word",
                                                maxWidth: '230px',
                                                textAlign: 'left',
                                                '@media only screen and (min-width: 350px) and (max-width: 899px)': {
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                }
                                            }}>
                                                {title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    doc?.shared_status === 1 ?
                                        <Grid item sx={{ '& svg': { '& path': { stroke: theme?.palette?.primary?.slashBrainIcon } } }}>
                                            <Icon icon="userIcon" />
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    )
                })
                }
                <Grid item xs={12}>
                    <Typography sx={{ display: mapData?.length === 0 ? 'flex' : 'none', alignItems: "center", justifyContent: "center", color: theme?.palette?.text?.secondary, fontSize: '12px', padding: '20px', fontWeight: 700 }}>No docs</Typography>
                </Grid>
                {isLoading &&
                    <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: 'center', minHeight: '400px', padding: '0px 16px 8px', position: 'absolute' }}>
                        <CircularProgress />
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default SearchAndDocList