import { Box, Button, Typography, useTheme } from "@mui/material";
import ColorPickerTool from "react-gcolor-picker";
import customColorPickerStyles from "./style";
import { colors } from "../../Elements/Color Picker/defaultColors";
import { useMemo } from "react";
import { useEditorContext } from "../../hooks/useMouseMove";

function CustomColorPicker(props) {
  const {
    gradient,
    color,
    onChange,
    defaultColors,
    recentColors = [],
    closeDrawer,
    hideThemeColors,
    disableEditTheme,
  } = props;

  const { setOpenTheme } = useEditorContext();

  const classes = customColorPickerStyles();

  const theme = useTheme();
  const colorVars = theme?.vars?.colors || {};

  const colorTabs = useMemo(() => {
    const otherColors = {
      title: "Recent colours",
      colors: [...recentColors, ...defaultColors],
      onChange: onChange,
    };

    const themeColors = Object.values(colorVars);

    const tabs = [];

    if (themeColors?.length && !hideThemeColors) {
      tabs.push({
        title: "Theme colours",
        onEdit: () => {
          setOpenTheme("editThemeColor");
          closeDrawer();
        },
        colors: Object.values(colorVars),
        onChange: onChange,
      });
    }

    tabs.push(otherColors);

    return tabs;
  }, [recentColors, defaultColors, onChange, colorVars]);

  return (
    <Box component="div" sx={classes.customColorPickerContainer}>
      <ColorPickerTool
        gradient={gradient}
        value={color?.startsWith("var") ? "" : color}
        onChange={onChange}
        defaultColors={[]}
        popupWidth="300"
      />

      {colorTabs.map((tab, i) => {
        const { title, onEdit, colors = [], onChange } = tab;

        return (
          <Box component="div" className="customColorPickerWrapper" key={i}>
            <Box component={"div"} className="colorTitleWrapper">
              <Typography variant="subtitle2">{title}</Typography>
              {onEdit && !disableEditTheme ? (
                <Button
                  variant="text"
                  className="editBtn"
                  onClick={() => onEdit(tab)}
                >
                  Edit
                </Button>
              ) : null}
            </Box>

            <Box component="div" className="colorPickerBtnContainer">
              {colors.map((colorVar, i) => {
                const isSelected = colorVar === color;

                return (
                  <Box
                    className={isSelected ? "colorPicketBtnActive" : ""}
                    key={i}
                    component="div"
                  >
                    <button
                      style={{
                        background: colorVar,
                      }}
                      onClick={() => onChange(colorVar)}
                      className={`colorPicketBtn`}
                      type="button"
                    ></button>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

CustomColorPicker.defaultProps = {
  defaultColors: [...colors],
  closeDrawer: () => {},
};

export default CustomColorPicker;
