import { Transforms, Editor, Range, Element, Path, Node } from "slate";
import { ReactEditor } from "slate-react";
import { customInsertNode, getNode } from "./helper";

export const DEFAULT_TABLE_NODE = () => ({
  type: "table",
  children: [
    {
      type: "table-row",
      children: [
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
      ],
    },
    {
      type: "table-row",
      children: [
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
      ],
    },
    {
      type: "table-row",
      children: [
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
        {
          type: "table-cell",
          children: [
            {
              type: "paragraph",
              children: [{ text: "" }],
              cellBgColor: "#FFFFFF",
            },
          ],
          size: { width: 120 },
        },
      ],
    },
  ],
  rows: 3,
  columns: 3,
});

const isFreeGridTable = (n) =>
  !Editor.isEditor(n) &&
  Element.isElement(n) &&
  n.type === "freegridItem" &&
  n.childType === "table";

const prefixKey = (obj, pk = "") => {
  return Object.keys(obj).reduce((a, b) => {
    a[`${pk}${b}`] = obj[b];
    return a;
  }, {});
};

const parseByPrefixKey = (obj, pk = "") => {
  return Object.keys(obj).reduce((a, b) => {
    if (b.indexOf(pk) !== -1 && pk) {
      const key = b.split(pk)[1];
      a[key] = obj[b];
    }
    return a;
  }, {});
};

export class TableUtil {
  constructor(editor) {
    this.editor = editor;
  }

  insertTable = (rows, columns) => {
    const [tableNode] = Editor.nodes(this.editor, {
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      mode: "highest",
    });

    if (tableNode) return;
    if (!rows || !columns) {
      return;
    }
    //Creating a 2-d array of blank string as default text for the table
    const cellText = Array.from({ length: rows }, () =>
      Array.from({ length: columns }, () => "")
    );
    const newTable = createTableNode(cellText, rows, columns);
    customInsertNode(this.editor, newTable);
  };

  removeTable = () => {
    const [freeGridItem] = Editor.nodes(this.editor, {
      match: isFreeGridTable,
    });

    if (freeGridItem) {
      Transforms.removeNodes(this.editor, {
        match: isFreeGridTable,
      });
    } else {
      Transforms.removeNodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });
    }
  };

  duplicateTable = () => {
    const { selection } = this.editor;

    if (!!selection && Range.isCollapsed(selection)) {
      const [freeGridItem] = Editor.nodes(this.editor, {
        match: isFreeGridTable,
      });

      let clone;
      let path;

      if (freeGridItem) {
        const [freeGridNode, freeGridPath] = freeGridItem;
        clone = freeGridNode;
        path = freeGridPath;
      } else {
        const [[tableNode, tablePath]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });

        clone = tableNode;
        path = tablePath;
      }

      const nextPath = Path.next(path);

      if (clone) {
        const clonedNode = JSON.parse(JSON.stringify(clone));

        Transforms.insertNodes(this.editor, clonedNode, {
          at: nextPath,
        });
      }
    }
  };

  getDOMNode = (path) => {
    try {
      const [tableNode] = Editor.nodes(this.editor, {
        at: path,
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });
      const tableDOM = ReactEditor.toDOMNode(this.editor, tableNode[0]);
      return tableDOM;
    } catch (err) {
      // console.log(err);
    }
  };

  getTotalWidth = (path, colCount = 0) => {
    try {
      let totalWidth = 0;
      for (let i = 0; i < colCount; i++) {
        const dom = ReactEditor.toDOMNode(
          this.editor,
          Node.get(this.editor, [...path, 0, i])
        );
        totalWidth += parseFloat(dom.style.width);
      }
      return totalWidth;
    } catch (err) {
      console.log(err);
    }
  };

  insertRow = (action) => {
    const { selection } = this.editor;

    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
      });
      if (tableNode) {
        const [[table, tablePath]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });
        const [currentRowData, currentRow] = tableNode;

        const isDuplicate = action === "duplicate";

        const isInsertNext = action === "after" || isDuplicate;

        const path = isInsertNext ? Path.next(currentRow) : currentRow;

        const insertData = isDuplicate
          ? JSON.parse(JSON.stringify(currentRowData))
          : createRowOnInsertAbove(currentRowData, currentRow, this.editor);

        Transforms.insertNodes(this.editor, insertData, {
          at: path,
        });
        Transforms.setNodes(
          this.editor,
          { rows: table.rows + 1 },
          {
            at: tablePath,
          }
        );
      }
    }
  };

  clearRow = () => {
    const { selection } = this.editor;

    if (!!selection && Range.isCollapsed(selection)) {
      const [tableRow] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
      });

      if (tableRow) {
        const [tableRowNode, tableRowPath] = tableRow;

        tableRowNode?.children?.forEach((cell, index) => {
          const currentCellPath = [...tableRowPath, index];

          clearCellText(this.editor, currentCellPath);
        });
      }
    }
  };

  deleteRow = () => {
    try {
      const { selection } = this.editor;

      if (!!selection && Range.isCollapsed(selection)) {
        const [tableNode] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === "table-row",
        });
        if (tableNode) {
          const [[table, tablePath]] = Editor.nodes(this.editor, {
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
          });
          const [, currentRow] = tableNode;

          const path = currentRow;

          Transforms.removeNodes(this.editor, {
            at: path,
          });

          Transforms.setNodes(
            this.editor,
            { rows: table.rows - 1 },
            {
              at: tablePath,
            }
          );
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  insertColumn = (action) => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });
      if (tableNode) {
        const [[table, tablePath]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });
        const [, currentCell] = tableNode;
        const startPath =
          action === "after" ? Path.next(currentCell) : currentCell;

        // The last two indices of the path represents the row and column. We need to add one cell to each row starting from the first row
        startPath[startPath.length - 2] = 0;
        for (let row = 0; row < table.rows; row++) {
          Transforms.insertNodes(this.editor, createTableCell(""), {
            at: startPath,
          });
          startPath[startPath.length - 2]++;
        }

        Transforms.setNodes(
          this.editor,
          { columns: table.columns + 1 },
          {
            at: tablePath,
          }
        );
      }
    }
  };

  duplicateColumn = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });
      if (tableNode) {
        const [[table, tablePath]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });
        const [, currentCell] = tableNode;

        const currentCellPath = currentCell;
        const insertNextCellPath = Path.next(currentCell);

        for (let row = 0; row < table.rows; row++) {
          currentCellPath[currentCellPath.length - 2] = row;
          insertNextCellPath[insertNextCellPath?.length - 2] = row;

          const cellNode = getNode(this.editor, currentCellPath);
          Transforms.insertNodes(
            this.editor,
            JSON.parse(JSON.stringify(cellNode)),
            {
              at: insertNextCellPath,
            }
          );
        }

        Transforms.setNodes(
          this.editor,
          { columns: table.columns + 1 },
          {
            at: tablePath,
          }
        );
      }
    }
  };

  clearColumn = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });
      if (tableNode) {
        const [[table]] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
        });
        const [, currentCell] = tableNode;

        const currentCellPath = currentCell;

        for (let row = 0; row < table.rows; row++) {
          currentCellPath[currentCellPath.length - 2] = row;

          clearCellText(this.editor, currentCellPath);
        }
      }
    }
  };

  deleteColumn = () => {
    try {
      const { selection } = this.editor;
      if (!!selection && Range.isCollapsed(selection)) {
        const [tableNode] = Editor.nodes(this.editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === "table-cell",
        });
        if (tableNode) {
          const [[table, tablePath]] = Editor.nodes(this.editor, {
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
          });
          const [, currentCell] = tableNode;
          const startPath = currentCell;

          // The last two indices of the path represents the row and column. We need to add one cell to each row starting from the first row
          startPath[startPath.length - 2] = 0;
          for (let row = 0; row < table.rows; row++) {
            Transforms.removeNodes(this.editor, {
              at: startPath,
            });
            startPath[startPath.length - 2]++;
          }

          Transforms.setNodes(
            this.editor,
            { columns: table.columns - 1 },
            {
              at: tablePath,
            }
          );
        }
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  updateTableStyle = (styleProps, paths) => {
    try {
      const { selection } = this.editor;
      if (!!selection && Range.isCollapsed(selection)) {
        const tableProps = parseByPrefixKey(styleProps, "table.");
        const rowProps = parseByPrefixKey(styleProps, "row.");
        const cellProps = parseByPrefixKey(styleProps, "col.");
        const { currentCellPath, currentRowPath, currentTablePath } = paths;
        Transforms.setNodes(
          this.editor,
          { ...tableProps },
          { at: currentTablePath }
        );
        Transforms.setNodes(
          this.editor,
          { ...rowProps, tableBorder: tableProps?.borderColor },
          { at: currentRowPath }
        );
        Transforms.setNodes(
          this.editor,
          {
            ...cellProps,
            rowBorder: rowProps?.borderColor,
            tableBorder: tableProps?.borderColor,
          },
          { at: currentCellPath }
        );

        applyColumnStyle(
          this.editor,
          currentCellPath,
          currentTablePath,
          cellProps,
          tableProps?.rows
        );

        // cell bg entire
        if (
          cellProps?.entireBgColor ||
          tableProps?.borderColor ||
          rowProps?.borderColor
        ) {
          const { rows } = tableProps;

          for (let r = 0; r < rows; r++) {
            Transforms.setNodes(
              this.editor,
              {
                entireBgColor: cellProps?.entireBgColor,
              },
              { at: [currentCellPath[0], r, currentCellPath[2]] }
            );
          }
        }

        // cell border all
        if (tableProps?.borderColor || rowProps?.borderColor) {
          const { rows, columns } = tableProps;

          for (let r = 0; r < rows; r++) {
            for (let c = 0; c < columns; c++) {
              Transforms.setNodes(
                this.editor,
                {
                  entireBorderColor: tableProps?.borderColor,
                  rowBorder: rowProps?.borderColor,
                },
                { at: [currentCellPath[0], r, c] }
              );
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  resizeTableCell = (styleProps, path) => {
    const cellProps = parseByPrefixKey(styleProps, "col.");

    Transforms.setNodes(
      this.editor,
      {
        ...cellProps,
      },
      { at: path }
    );
  };

  getTableProps = () => {
    const { selection } = this.editor;
    if (!!selection && Range.isCollapsed(selection)) {
      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });

      const [tableCellNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === "table-cell",
      });
      const [tableRowNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table-row",
      });

      if (tableNode && tableCellNode && tableRowNode) {
        const [currentTable, currentTablePath] = tableNode;
        const [currentCell, currentCellPath] = tableCellNode;
        const [currentRow, currentRowPath] = tableRowNode;
        const startPath = currentCell;
        Transforms.setNodes(
          this.editor,
          { cellBgColor: "#FFFFFF" },
          {
            at: startPath,
          }
        );

        if (currentTable && currentCell && currentRow) {
          const currentTableProps = { ...currentTable };
          delete currentTableProps.children;
          delete currentTableProps.type;

          const currentCellProps = { ...currentCell };
          delete currentCellProps.children;
          delete currentCellProps.type;

          const currentRowProps = { ...currentRow };
          delete currentRowProps.children;
          delete currentRowProps.type;

          return {
            styleProps: {
              ...prefixKey({ ...currentTableProps }, "table."),
              ...prefixKey({ ...currentRowProps }, "row."),
              ...prefixKey({ ...currentCellProps }, "col."),
            },
            currentCellPath,
            currentRowPath,
            currentTablePath,
          };
        }

        return null;
      }
      return null;
    }
  };

  isCellSelected = (selection) => {
    try {
      if (!selection) {
        return false;
      }

      const [tableNode] = Editor.nodes(this.editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === "table",
      });

      // const [, tableCellPath] = Editor.nodes(this.editor, {
      //   at: selection,
      //   match: (n) =>
      //     !Editor.isEditor(n) &&
      //     Element.isElement(n) &&
      //     n.type === "table-cell",
      // });
      const { anchor, focus } = this.editor.selection || {};
      if (tableNode && tableNode[0] && focus?.path) {
        let startCell = anchor?.path;
        let endCell = focus?.path;

        // swap for reverse selection
        if (startCell[1] > endCell[1]) {
          startCell = focus?.path;
          endCell = anchor?.path;
        } else if (startCell[2] > endCell[2]) {
          startCell = focus?.path;
          endCell = anchor?.path;
        }

        const { columns } = tableNode[0];
        const startPath = startCell[0];
        const startRow = startCell[1];
        let startCol = startCell[2];
        const endRow = endCell[1];
        const endCol = endCell[2];
        const selectedCellsPath = [];
        let uptoCol = startRow !== endRow ? columns - 1 : endCol;
        for (let row = startRow; row <= endRow; row++) {
          for (let col = startCol; col <= uptoCol; col++) {
            let nextPath = [startPath, row, col];
            selectedCellsPath.push(nextPath);
          }
          startCol = 0;
          uptoCol = endCol;
        }
        return selectedCellsPath;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

const createRow = (cellText, other) => {
  const newRow = Array.from(cellText, (value) => createTableCell(value, other));
  return {
    type: "table-row",
    children: newRow,
  };
};

export const createTableCell = (text, other = {}) => {
  return {
    type: "table-cell",
    children: [
      {
        type: "paragraph",
        children: [{ text }],
      },
    ],
    size: { width: 120 },
    ...other,
  };
};

const createRowOnInsertAbove = (currentRow, currRowPath, editor) => {
  const isFirstRow = currRowPath[currRowPath?.length - 1] === 0;

  const rowChild = currentRow?.children?.map((cell, i) => {
    let other = {};

    // remove the current row's size and add it on the currently inserting cell
    if (isFirstRow) {
      const cellPath = [...currRowPath, i];
      Transforms.setNodes(editor, { size: null }, { at: cellPath });
      other = { size: cell?.size };
    }

    return createTableCell("", other);
  });

  return {
    type: "table-row",
    children: rowChild,
  };
};

// const createHead = (cellText) => {
//   const newHead = Array.from(cellText, (value) => createTableHeadCell(value));
//   return {
//     type: "table-head",
//     children: [
//       {
//         type: "table-row",
//         children: newHead,
//       },
//     ],
//   };
// };

// export const createTableHeadCell = (text) => {
//   return {
//     type: "table-cell",
//     children: [
//       {
//         type: "paragraph",
//         children: [{ text }],
//       },
//     ],
//   };
// };

// const createTbody = (children) => {
//   return [
//     {
//       type: "table-body",
//       children: children,
//     },
//   ];
// };

const createTableNode = (cellText, rows, columns) => {
  // const tableHead = Array.from([cellText[0]], (value) => createHead(value));
  const tableChildren = Array.from(cellText, (value) => createRow(value));
  // const tbodyChildren = createTbody(tableChildren);
  let tableNode = {
    type: "table",
    children: tableChildren,
    rows,
    columns,
  };
  return tableNode;
};

const columnStyleKeys = [
  "entireBgColor",
  "entireBorderColor",
  "entireTextColor",
  "entireFontFamily",
  "entireFontWeight",
  "entireTextSize",
];
const applyColumnStyle = (
  editor,
  currentCellPath,
  currentTablePath,
  cellProps,
  rows
) => {
  const colStyle = columnStyleKeys.reduce((acc, key) => {
    const style = cellProps[key];
    if (style) {
      acc[key] = style;
    }
    return acc;
  }, {});

  for (let r = 0; r < rows; r++) {
    const cellPosition = currentCellPath[currentCellPath?.length - 1]; // cell position on each row as per selected column cell

    Transforms.setNodes(editor, colStyle, {
      at: [...currentTablePath, r, cellPosition],
    });
  }
};

export const clearCellText = (editor, currentCellPath) => {
  try {
    const existingCellNode = getNode(editor, currentCellPath);

    Transforms.removeNodes(editor, {
      at: currentCellPath,
    });

    Transforms.insertNodes(
      editor,
      {
        ...(existingCellNode || {}),
        children: [
          {
            type: "paragraph",
            children: [{ text: "" }],
            cellBgColor: "#FFFFFF",
          },
        ],
      },
      { at: currentCellPath }
    );
  } catch (err) {
    console.log(err);
  }
};

export const getTableColumns = (element) => {
  const { columns, children } = element || {};

  if (columns) {
    return columns;
  }

  const firstRow = children?.length ? children[0] : {};

  return firstRow?.children?.length || 0;
};

export const getTableRows = (element) => {
  const { rows, children } = element || {};

  if (rows) {
    return rows;
  }

  return children?.length || 0;
};
