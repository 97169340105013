import React from "react";
import { Node, Transforms } from "slate";
import { Box } from "@mui/material";
import { StyleContent } from "../../../StyleBuilder";
import tableStyle from "../../../StyleBuilder/tableStyle";
import { TableUtil } from "../../../../utils/table";

const TableSettings = (props) => {
  const { editor, path, customProps, onClose } = props;
  const item_path = path?.split("|").map((m) => parseInt(m));
  const element_path = [...item_path, 0];
  const element = Node.get(editor, element_path);

  const getTableProps = () => {
    try {
      const firstTCell = [...element_path, 0, 0, 0, 0];
      const firstTCellTarget = {
        anchor: {
          path: [...firstTCell],
          offset: 0,
        },
        focus: {
          path: [...firstTCell],
          offset: 0,
        },
      };
      Transforms.select(editor, firstTCellTarget);
      const table = new TableUtil(editor);
      const tableProps = table.getTableProps();
      return {
        table,
        tableProps,
      };
    } catch (err) {
      return element;
    }
  };

  const onChange = (data) => {
    try {
      const { table, tableProps } = getTableProps();
      const updateData = { ...data };
      delete updateData.children;
      delete updateData.type;
      table.updateTableStyle(updateData, {
        ...tableProps,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Box component={"div"} className="item-w">
      {tableStyle?.map((m, i) => {
        return (
          <StyleContent
            key={`tab_${m.value}_$${i}`}
            renderTabs={tableStyle}
            value={m.value}
            element={getTableProps()?.tableProps?.styleProps || {}}
            onChange={onChange}
            customProps={customProps}
            handleClose={handleClose}
          />
        );
      })}
    </Box>
  );
};

export default TableSettings;
