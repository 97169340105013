const usePopupStyle = (theme) => ({
  popupWrapper: {
    boxShadow:
      theme?.palette?.type === "light" ? "1px 2px 15px 0px #2563EB40" : "unset",
    zIndex: 1300,
    marginBottom: "12px !important",
    borderRadius: "20px !important",
    border: `1px solid ${theme?.palette?.editor?.popUpBorderColor} !important`,
    maxWidth: "100%",
    // maxHeight: "40px",
    "&.fullscreen": {
      marginBottom: "0px !important",
      borderRadius: "0px !important",
      "& .papper-wrpr": {
        boxShadow: "none",
      },
    },
    "&.xs": {
      transform: "none !important",
    },
    "& .MuiPaper-root": {
      backgroundColor: `${theme?.palette?.editor?.textFormatBgColor} !important`,
      borderRadius: "19px !important",
    },
    "& .MuiDialog-paperFullScreen": {
      borderRadius: "0px !important",
    },
    "& .inputField": {
      height: "29px",
      "& .MuiIconButton-root": {
        padding: "5px",
      },
      "& .MuiOutlinedInput-root ": {
        background: `${theme?.palette?.editor?.inputFieldBgColor}`,
        borderRadius: "8px",
        border: `1px solid ${theme?.palette?.editor?.inputFieldBorder}`,
      },
      "& .MuiFormControl-root": {
        background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        borderRadius: "8px",
        height: "29px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: `1px solid transparent`,
      },
      "& .MuiOutlinedInput-input": {
        padding: "3px 35px 4px 2px !important",
      },
      "& .MuiInputBase-adornedStart": {
        padding: "0px 0px 0px 9px",
      },
      "& svg": {
        "& path": {
          stroke: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        },
      },
    },

    "&.textSettings": {
      "@media only screen and (max-width: 600px)": {
        margin: "4px !important",
        marginBottom: "54px !important",
      },
    },

    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textFormatTextColor,
    },
    "& .MuiInputBase-root": {
      color: theme?.palette?.editor?.textColor,
      "& svg": {
        color: theme?.palette?.editor?.textColor,
      },
      "&.MuiOutlinedInput-root:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme?.palette?.editor?.textColor}`,
        },
      },
    },
    "& .MuiCheckbox-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& button": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .buttonsWrpr": {
      "& button": {
        color: theme?.palette?.editor?.textColor,
      },
      "& .more-btn-cbs": {
        color: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        border: `1px solid ${theme?.palette?.editor?.closeButtonSvgStroke}`,
        paddingTop: "10px",
        "& svg": {
          width: "20px",
          height: "24px",
        },
      },
    },
    "@media only screen and (max-width: 599px)": {
      // margin: "10px !important",
      background: "unset",
      boxShadow: "unset",
      // border: "none",
    },
  },
  textFormatWrapper: {
    padding: "0px 16px 16px 16px",
    width: "323px",
    maxWidth: "100%",
    overflowX: "hidden !important",
    // 30% of window height
    maxHeight: `${window.innerHeight * 0.45}px`,
    overflow: "auto",
    "&.elements-wrpr-pp": {
      width: "500px",
      maxWidth: "100%",
      "&.fullscreen": {
        width: "100%",
        maxHeight: "fit-content",
      },
      "& .ele-item-single": {
        paddingLeft: "4px",
        paddingTop: "4px",
      },
      "@media only screen and (max-width: 599px)": {
        width: "330px",
      },
      "& .accordionIcon": {
        "& p": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTypography-root": {
      padding: "0px",
    },
    "&.templates": {
      width: "500px",
      maxWidth: "100%",
      "&.fullscreen": {
        width: "100%",
        maxHeight: "fit-content",
      },
      "& .MuiTab-root": {
        textTransform: "capitalize",
        color: "#64748B",
        borderBottom: `1.5px solid ${theme?.palette?.editor?.deviderBgColor}`,
      },
      "& .Mui-selected": {
        color: `${theme?.palette?.editor?.textColor} !important`,
        fontWeight: "700 !important",
      },
      "& .MuiTabs-indicator": {
        background: "#2563EB",
      },
      "& .MuiTabScrollButton-horizontal": {
        borderBottom: "unset !important",
        width: "10px",
        "& svg": {
          color: theme?.palette?.editor?.closeButtonSvgStroke,
        },
      },
      "@media only screen and (max-width: 599px)": {
        width: "330px",
      },
      "& .MuiCardContent-root": {
        color: theme?.palette?.editor?.textColor,
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    "& .headerContainer": {},
    "& .textSettingHeader": {
      // borderBottom: "1px solid #DCE4EC",
      padding: "8px 0px 5px",
      marginBottom: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& .MuiOutlinedInput-root": {
      boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
    },
    "& .mini": {
      width: "100%",
      justifyContent: "start",
      borderRadius: "10px !important",
      transition: "background-color 0.3s ease",
      "& .signatureElementIcon": {
        "& path": {
          fill: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        },
      },
      "& .commonSvgStyle": {
        "& path": {
          stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        },
      },
      "& .commonSvgStyle2": {
        "& path": {
          stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        },
      },
      "& .colorBoxElementIcon": {
        "& path": {
          stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
          fill: "none",
        },
      },
      "& .gridElementIcon": {
        "& path": {
          stroke: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
          fill: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        },
      },
      "& .newLineElementIcon": {
        "& path": {
          fill: `${theme?.palette?.editor?.closeButtonSvgStroke}`,
        },
      },
      "&:hover": {
        backgroundColor: `${theme?.palette?.editor?.menuOptionHoverBackground} !important`,
        "& .signatureElementIcon": {
          "& path": {
            fill: `${theme?.palette?.editor?.textColor}`,
          },
        },
        "& .commonSvgStyle": {
          "& path": {
            stroke: `${theme?.palette?.editor?.textColor}`,
          },
        },
        "& .commonSvgStyle2": {
          "& path": {
            stroke: `${theme?.palette?.editor?.textColor}`,
          },
        },
        "& .colorBoxElementIcon": {
          "& path": {
            stroke: `${theme?.palette?.editor?.textColor}`,
            fill: "none",
          },
        },
        "& .gridElementIcon": {
          "& path": {
            stroke: `${theme?.palette?.editor?.textColor}`,
            fill: `${theme?.palette?.editor?.textColor}`,
          },
        },
        "& .newLineElementIcon": {
          "& path": {
            fill: `${theme?.palette?.editor?.textColor}`,
          },
        },
      },
      "& svg": {
        width: "24px",
        height: "19px",
        marginRight: "8px",
      },
      "& span": {
        fontSize: "14px",
        color: "#0F172A",
      },
    },
    // for command list style
    "& .cmd": {
      borderRadius: "0px",
      width: "100%",
      justifyContent: "start",
      "& svg": {
        width: "24px",
        height: "24px",
        marginRight: "8px",
      },
      "& span": {
        fontSize: "14px",
        color: "#64748B",
      },
    },
    "& .MuiTab-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& button": {
      "&:hover": {
        "& span": {
          color: theme?.palette?.editor?.activeColor,
        },
      },
    },
    "@media only screen and (max-width: 599px)": {
      width: "330px",
    },
    "& .textFormatMUIIcon": {
      "& svg": {
        color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
      },
    },
    "& .npr-0": {
      paddingRight: "0px !important",
    },
  },
  textFormatLabel: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "25px",
    marginTop: "12px",
    marginBottom: "8px",
    "& .searchContainer": {
      position: "absolute",
      right: 0,
      zIndex: 3,
    },
    "& .MuiFormControl-root.MuiTextField-root input": {
      padding: "8px 35px 6px 12px",
    },
    "& .toogleFullScreenBtn": {
      background: `${theme?.palette?.editor?.closeButtonBgColor} !important`,
      padding: "4px !important",
      "& .toogleFullScreenSvg": {
        "& path": {
          stroke: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
        },
      },
    },
  },
  textSettingHeader: {
    display: "flex",
    borderBottom: `1px solid ${theme?.palette?.editor?.deviderBgColor} !important`,
  },
  textFormatField: {
    marginBottom: "8px",
    marginTop: "8px",
  },
  textFormatField1: {
    marginBottom: "16px",
    marginTop: "10px",
  },
  textFormatField2: {
    // marginBottom: "16px",
    marginTop: "10px",
  },
  textFormatField3: {
    marginBottom: "16px",
  },
  textAlignButtons: {
    paddingLeft: "0px",
    paddingRight: "12px",
    "&:hover": {
      background: "unset",
    },
    "&:not(.btnActive):hover": {
      background: "unset",
      color: `${theme?.palette?.editor?.textColor} !important`,
      "& .justifyIcon": {
        "& path": {
          fill: `${theme?.palette?.editor?.textColor}`,
          stroke: `${theme?.palette?.editor?.textColor}`,
        },
      },
      "& .textAlignIconSameStyles": {
        "& path": {
          fill: `${theme?.palette?.editor?.textColor}`,
        },
      },
      "& .orderedListIcon": {
        "& .strokePathList": {
          stroke: `${theme?.palette?.editor?.textColor} !important`,
        },
        "& .fillPathList": {
          fill: `${theme?.palette?.editor?.textColor} !important`,
        },
      },
      "& .bulletedListTextIcon": {
        "& path": {
          fill: `${theme?.palette?.editor?.textColor}`,
          stroke: `${theme?.palette?.editor?.textColor}`,
        },
        "& circle": {
          fill: `${theme?.palette?.editor?.textColor}`,
        },
      },
      "& .checkedListTextIcon": {
        "& path": {
          stroke: `${theme?.palette?.editor?.textColor}`,
        },
      },
      "& .accordianListTextIcon": {
        // stroke: `${theme?.palette?.editor?.svgTextAlignStrokeColor} !important`,
        "& svg": {
          fill: `${theme?.palette?.editor?.textColor}`,
          stroke: `${theme?.palette?.editor?.textColor}`,
        },
        "& path": {
          fill: `${theme?.palette?.editor?.textColor}`,
          stroke: `${theme?.palette?.editor?.textColor}`,
        },
      },
    },
  },
  autoCompleteaFontFamily: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor}`,
      fontSize: "12px",
      fontWeight: "400",
      height: "36px",
      paddingLeft: "12px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.inputFieldBorder} !important`,
    },
    '& .MuiInputBase-root':{
      '& input': {
        border: "none !important"
      }
    },
    "& svg": {
      width: "20px",
      height: "24px",
    },
  },
  fontFamilyListOptions: {
    "& .MuiAutocomplete-listbox": {
      padding: "0px",
      "&::-webkit-scrollbar-thumb": {
        background: `${theme?.palette?.editor?.brainPopupScroll} !important`,
      },
      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },
      "& li": {
        margin: "5px",
        borderRadius: "8px",
        color: theme?.palette?.editor?.menuOptionTextColor,
        fontSize: "14px",
        padding: "8px 12px",
        '&[aria-selected="true"]': {
          backgroundColor: `${theme?.palette?.editor?.menuOptionSelectedOption} !important`,
        },
      },
    },
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: theme?.palette?.editor?.textWeightPopUpBackground,
    },
  },
  textFormatSelect: {
    background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
    width: "100%",
    height: "36px",
    borderRadius: "10px",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.inputFieldBorder} !important`,
    },
    "& svg": {
      width: "20px",
      height: "24px",
    },
  },
  textFormatSelectOptions: {
    margin: "5px !important",
    borderRadius: "4px !important",
    fontSize: "12px !important",
    fontWeight: 500,
    "&.Mui-selected": {
      background: `${theme?.palette?.editor?.menuOptionSelectedOption} !important`,
      color: `${theme?.palette?.editor?.menuOptionTextColor} !important`,
    },
  },
  textFormatFieldBorder: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.05)",
  },
  dividerGrid: {
    margin: "5px 0px 10px 0px",
    // background: `${theme?.palette?.editor?.deviderBgColor} !important`,
  },
  textFormatColorWrpr: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  textFormatCG: {
    alignItems: "flexStart",
    justifyContent: "center",
    flexDirection: "row",
    "& .accordionIcon": {
      paddingLeft: "0px",
      paddingRight: "12px",
      "&:not(.btnActive):hover": {
        background: "unset",
        "& .accordianIconSvgTextFormat": {
          "& path": {
            stroke: theme?.palette?.editor?.textColor,
          },
        },
      },
    },
  },
  evenSpace: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& button": {
      marginRight: "8px",
      marginBottom: "8px",
    },
    "&.typo-icons": {
      "& button": {
        width: "31px",
        height: "36px",
        "&:hover": {
          background: "unset",
        },
        "&:not(.btnActive):hover": {
          background: "unset",
          "& svg": {
            color: `${theme?.palette?.editor?.textColor} !important`,
          },
          "& .linkIcon": {
            "& path": {
              stroke: theme?.palette?.editor?.textColor,
            },
          },
        },
      },
      alignItems: "flexStart",
      // justifyContent: "center",
      flexDirection: "row",
      "&:hover": {
        background: "unset",
      },
      "& .customSelectTool": {
        color: theme?.palette?.editor?.closeButtonSvgStroke,
        fontWeight: "510",
        "& svg": {
          marginTop: "8px",
          width: "16px",
          height: "8px",
        },
      },
    },
    "&.text-decorations-wrpr": {
      "& button": {
        // width: "140px",
      },
    },
  },
  typoLabel: {
    fontSize: "14px",
    marginBottom: "5px",
    paddingLeft: "5px",
    marginTop: "4px",
    fontWeight: 500,
    color: theme?.palette?.editor?.textColor || "#000",
  },
  typoLabel2: {
    fontSize: "14px",
    marginBottom: "2px",
    // paddingLeft: "10px",
    fontWeight: 500,
  },
  typoLabel3: {
    fontSize: "14px",
    marginBottom: "10px",
    paddingLeft: "5px",
    marginTop: "4px",
    fontWeight: 500,
    color: theme?.palette?.editor?.textColor || "#000",
  },
  templateCard: {
    borderRadius: "10px",
    boxShadow: "none",
    cursor: "pointer",
  },
  templateCardMedia: {
    width: "100%",
    height: "210px",
    backgroundPosition: "left top",
    backgroundSize: "100% auto",
    borderRadius: "10px",
    boxShadow: "0px 1px 3px 3px rgba(0,0,0,0.12)",
    zIndex: 1,
    position: "relative",
    "&.fullscreen": {
      height: "280px",
    },
  },
  templateCardMediaWrpr: {
    position: "relative",
    padding: "4px",
    margin: "8px",
    marginBottom: "0px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#FEFEFE",
    overflow: "hidden",
    "&:hover": {
      border: "1px solid #2563EB",
      "& .template-card-action": {
        display: "flex",
      },
    },
    "& .img-loader-wrapper": {
      position: "absolute",
      width: "12px",
      height: "12px",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      zIndex: 0,
    },
  },
  templateCardsWrpr: {
    height: "100%",
    overflow: "auto",
    margin: "12px 0px",
    paddingRight: "8px",
    "&.fullscreen": {
      height: `${window.innerHeight - 197}px`,
    },
  },
  templateCardTitle: {
    fontSize: "12px",
    padding: "6px 8px",
  },
  pageWidthBtn: {
    color: "#64748B",
    marginTop: "8px",
    textTransform: "capitalize",
    "&.active": {
      backgroundColor: theme?.palette?.editor?.background,
      border: `1px solid transparent`,
      color: theme?.palette?.editor?.activeColor,
    },
  },
  btnGroup: {
    border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    borderRadius: "7px",
    backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
    height: "36px",
    "& button": {
      backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
      marginRight: "0px",
      color: theme?.palette?.editor?.textColor,
      borderColor: "#ffffff00 !important",
      textTransform: "capitalize",
      marginBottom: "0px",
      "&.active": {
        background: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.activeColor,
      },
      "&.no-hover": {
        border: `1px solid #ffffff00`,
      },
    },
    "& .colorBox": {
      background: theme?.palette?.editor?.inputFieldBgColor,
      borderRadius: "7px 0px 0px 7px",
      fontSize: "12px !important",
      fontWeight: 500,
    },
    "& .colorPicker": {
      background: theme?.palette?.editor?.inputFieldBgColor,
      borderRadius: "0px 7px 7px 0px",
      padding: "0px",
    },
    "& .vl": {
      background: theme?.palette?.editor?.borderColor,
      width: "2px",
      height: "25px",
      margin: "5px 0px",
    },
    "& svg": {
      width: "20px",
      height: "24px",
    },
  },
  btnGroup2: {
    border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    borderRadius: "7px",
    backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
    height: "36px",
    "& button": {
      backgroundColor: theme?.palette?.editor?.background,
      marginRight: "0px",
      color: theme?.palette?.editor?.textColor,
      borderColor: "#ffffff00 !important",
      textTransform: "capitalize",
      marginBottom: "0px",
      // "&:hover": {
      //   color: theme?.palette?.editor?.borderColor,
      //   background: theme?.palette?.editor?.background,
      // },
      "&.active": {
        background: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.activeColor,
      },
      "&.no-hover": {
        border: `1px solid #ffffff00`,
        borderRadius: "7px 0px 0px 7px",
      },
      "& svg": {
        width: "20px",
        height: "24px",
      },
    },
    "& .colorBox": {
      background: theme?.palette?.editor?.inputFieldBgColor,
      borderRadius: "7px 0px 0px 7px",
      fontSize: "12px !important",
      fontWeight: 500,
    },
    "& .colorPicker": {
      background: theme?.palette?.editor?.inputFieldBgColor,
      borderRadius: "0px 7px 7px 0px",
      padding: "0px",
    },
    "& .vl": {
      background: theme?.palette?.editor?.borderColor,
      width: "2px",
      height: "25px",
      margin: "5px 0px",
    },
  },
  allColor: {
    borderRadius: "7px !important",
    "& .buttonsWrpr": {
      backgroundColor: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
      border: `1px solid ${theme?.palette?.editor?.miniToolBarBorder} !important`,
      borderRadius: "7px !important",
      "& button": {
        color: theme?.palette?.editor?.textColor,
      },
    },
  },
  allColorInner: {
    borderRadius: "7px !important",
    "& .buttonsWrpr": {
      "& button": {
        // border: "1px solid #ccc",
        margin: "4px",
        width: "24px",
        height: "24px",
        "&.active": {
          "&:before": {
            content: '" "',
            position: "absolute",
            top: "-2px",
            left: "-2px",
            width: "calc(100% + 4px)",
            height: "calc(100% + 4px)",
            border: "3px solid blue",
            borderRadius: "50%",
          },
        },
      },
    },
  },
  defaultBtn1: {
    color: "#2563EB !important",
    textTransform: "none",
    textDecorationLine: "underline",
    textUnderlineOffset: "2px",
    padding: "0px",
  },
  defaultBtn2: {
    color: `${theme?.palette?.editor?.deafultColorOptionTextColor} !important`,
    textTransform: "none",
    textDecorationLine: "underline",
    textUnderlineOffset: "2px",
    padding: "0px",
  },
  defaultBtn: {
    color: "#2563EB !important",
    textTransform: "none",
    textDecoration: "underline",

    "&.Mui-disabled": {
      color: "#A0AEC0 !important",
      textDecoration: "none",
    },
  },
  templateCardBtnGrp: {
    display: "none",
    position: "absolute",
    flexDirection: "column",
    left: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    width: "100%",
    background: "linear-gradient(180deg, #0F172A00, #0f172ae0 96%)",
    padding: "10px",
    boxSizing: "border-box",
    "& .blueBtn": {
      background: "#2563EB !important",
      borderRadius: "7px",
      padding: "7px 15px",
      color: "#FFF",
      width: "100px",
      marginBottom: "10px",
      textTransform: "capitalize",
    },
    "& .outlineBtn": {
      border: "1px solid #D7DBEC",
      borderRadius: "7px",
      padding: "7px 15px",
      color: "#FFF",
      width: "100px",
      textTransform: "capitalize",
    },
  },
  searchBox: {
    "& input": {
      padding: "6px 12px",
    },
    "& svg": {
      color: "#CCC",
    },
  },
  textOptions: {
    backgroundColor: `${theme?.palette?.editor?.textWeightPopUpBackground} !important`,
    color: theme?.palette?.editor?.textColor,
    borderRadius: "8px !important",
    fontFamily: "Inter, sans-serif",
    "& .MuiList-root": {
      padding: "0px !important",
    },
  },
  textSize: {
    height: "36px !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px !important",
      height: "36px !important",
      background: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.inputFieldBorder} !important`,
    },
    "& .textFontArrows": {
      "& svg": {
        stroke: theme?.palette?.editor?.closeButtonSvgStroke,
        color: theme?.palette?.editor?.closeButtonSvgStroke,
        width: "10px",
        height: "12px",
      },
    },
  },
  closeBtn: {
    background: `${theme?.palette?.editor?.closeButtonBgColor} !important`,
    color: `${theme?.palette?.editor?.closeButtonSvgStroke} !important`,
  },
  colorPickerPopup: {
    margin: "30px",
    "& .MuiPaper-root": {
      overflow: "auto",
      backgroundColor: theme?.palette?.editor?.background,
      borderRadius: "14px !important",
      border: `1px solid ${theme?.palette?.editor?.inputFieldBorder} !important`,
    },
    "& .popup_tabs": {
      backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
      "@media only screen and (max-width: 899px)": {
        width: "100% !important",
      },
    },
    "& .popup_tabs-header": {
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "12px !important",
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
      "& .popup_tabs-header-label-active": {
        color: theme?.palette?.editor?.activeColor,
        backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
      },
    },
    "& .popup_tabs-body": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
    },
    "& .colorpicker": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
    },
    "& .color-picker-panel": {
      backgroundColor: theme?.palette?.editor?.miniToolBarBackground,
    },
    "& .input_rgba": {
      "& input": {
        backgroundColor: theme?.palette?.editor?.inputFieldBgColor,
        color: theme?.palette?.editor?.textColor,
        height: "32px !important",
        borderRadius: "10px !important",
        border: `1px solid ${theme?.palette?.editor?.inputFieldBorder} !important`,
        boxShadow: "none",
        "&:hover": {
          backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
        },
        "&:focus": {
          border: `1px solid ${theme?.palette?.editor?.inputFieldBorder} !important`,
          backgroundColor: `${theme?.palette?.editor?.inputFieldBgColor} !important`,
          boxShadow: "none",
        },
      },
      "& .input_rgba-hex-label": {
        top: "4px !important",
      },
      "& .input_rgba-alpha-label": {
        top: "6px !important",
      },
      "& .input_rgba-label": {
        display: "none",
      },
    },
    "& .default-color-panel": {
      gridTemplateColumns: "repeat(auto-fill, 24px)",
      "& .default-color-panel_item": {
        borderRadius: "50%",
        width: "16px",
        height: "16px",
      },
    },
  },
  colorPopper: {
    "& .MuiPaper-root": {
      backgroundColor: `${theme?.palette?.editor?.miniToolBarBackground} !important`,
      background: `${theme?.palette?.editor?.miniToolBarBackground}`,
      borderRadius: "7px !important",

      "@media only screen and (max-width: 600px)": {
        marginTop: "-40px",
      },
    },
  },
  fullViewCardMedia: {
    width: "100%",
    backgroundPosition: "left top",
    backgroundSize: "100% auto",
    zIndex: 1,
    position: "relative",
    "&.fullscreen": {
      height: "130px",
    },
    "&.smallCard": {
      height: "130px",
    },
    "&.mediumCard": {
      height: "250px",
      boxShadow: "0px 1px 3px 3px rgba(0,0,0,0.12)",
    },
  },
  buttonCardMedia: {
    width: "100%",
    height: "48px",
    backgroundPosition: "left top",
    backgroundSize: "contain",
    zIndex: 1,
    position: "relative",
    "&.fullscreen": {
      height: "48px",
    },
  },
  buttonCardMediaWrpr: {
    position: "relative",
    margin: "8px",
    marginBottom: "0px",
    "&:hover": {
      "& .template-card-action": {
        display: "flex",
      },
    },
    "& .img-loader-wrapper": {
      position: "absolute",
      width: "12px",
      height: "12px",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      zIndex: 0,
    },
  },
  paperOverrides: {
    "&.MuiPaper-root": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  miniTextFormatWrapper: {
    maxWidth: "100%",
    padding: "2px 8px",
    overflowX: "auto",

    "& .customSelectTool": {
      padding: "0px 8px",
      color: theme?.palette?.secondary?.main,
      gap: "4px",
    },

    "& .verticalLine": {
      borderLeft: `1px solid ${theme?.palette?.primary?.border8 || "black"}`,
      minHeight: "20px",
      height: "100%",
    },

    "& .toolWrapper": {
      display: "flex",
      alignItems: "center",

      "& .mr-1": {
        marginRight: "8px",
      },

      "& .ml-1": {
        marginLeft: "8px",
      },
    },

    "& button": {
      minWidth: "0px !important",
      textTransform: "none",
    },

    "& .fontColorBtn": {
      color: theme?.palette?.secondary?.main,
      borderRadius: "unset",
      fontSize: "17px",
      padding: "0px",
      margin: "0px 8px",
      paddingBottom: "5px",
      lineHeight: "16px",
      position: "relative",

      "& .selectedColor": {
        height: "3px",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      },
    },

    "& .textSettingsIcon": {
      minWidth: "auto !important",
    },
  },
  customSelectPopoverWrapper: {
    "& .MuiPopover-paper": {
      maxHeight: "140px",
      // minWidth: "130px",
      // border: "1px solid #E4E8EB",
      background: `${theme?.palette?.editor?.textWeightPopUpBackground} !important`,
      overflowY: "scroll",
      padding: "3px 12px 8px 2px",
      borderRadius: "8px",
      "@media only screen and (max-width: 600px)": {
        marginTop: "-40px",
      },
    },

    "& .customSelectOptionLabel": {
      color: theme?.palette?.editor?.textColor || "black",
      margin: "6px 6px 0px 6px",
      width: "100%",
      justifyContent: "start",
      paddingRight: "20px",
      fontSize: "14px",
      lineHeight: "1.75 !important",
      fontWeight: 400,
      // background: theme?.palette?.editor?.background || "#fff",
      overflowY: "hidden",
      "&:hover": {
        background: `${theme?.palette?.action?.selected} !important`,
      },

      "&.selected": {
        color: `${theme?.palette?.editor?.menuOptionTextColor} !important`,
        background: `${theme?.palette?.editor?.menuOptionSelectedOption} !important`,
        "& .orderedListIcon": {
          "& .strokePathList": {
            stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
          },
          "& .fillPathList": {
            fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
          },
        },
        "& .bulletedListTextIcon": {
          "& path": {
            fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
            stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
          },
          "& circle": {
            fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
          },
        },
        "& .checkedListTextIcon": {
          "& path": {
            stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
          },
        },
        "& .accordianIconSvgTextFormat": {
          "& path": {
            stroke: `${theme?.palette?.editor?.menuOptionsSelectedTextColor} !important`,
          },
        },
        "& .textAlignIconSameStyles": {
          "& path": {
            fill: `${theme?.palette?.editor?.menuOptionsSelectedTextColor}`,
          },
        },
      },
    },

    "& .menuOptions": {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },

    "& button": {
      minWidth: "0px !important",
      textTransform: "none",
    },
  },
  // mobileMiniTextWrapper: {
  //   boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
  //   position: "fixed",
  //   bottom: "env(safe-area-inset-bottom)",
  //   left: 0,
  //   width: "100%",
  // },
  miniFontSizeInput: {
    "& input": {
      fontSize: "14px",
      width: "16px",
      padding: "8px 4px",
    },

    "& fieldset": {
      border: "none !important",
    },
  },
});

export default usePopupStyle;
