import { fontOptions } from "../utils/font";
import FormatBoldIcon from "@mui/icons-material/FormatBold";

export const toolbarGroups = [
  [
    {
      id: 1,
      format: "fontFamily",
      type: "fontfamilydropdown",
      options: fontOptions,
    },
    {
      id: 2,
      format: "fontSize",
      type: "numberInput",
      options: [
        { text: "12px", value: "12px" },
        { text: "16px", value: "16px" },
        { text: "28px", value: "28px" },
        { text: "48px", value: "48px" },
      ],
    },
    {
      id: 40,
      format: "fontWeight",
      type: "dropdown",
      options: [
        { text: "Normal", value: "normal", numVal: "400" },
        { text: "Bold", value: "bold", numVal: "700" },
        { text: "Bolder", value: "bolder", numVal: "700" },
        { text: "Lighter", value: "lighter", numVal: "100" },
      ],
      icon: FormatBoldIcon,
      width: "100px",
    },
  ],
  [
    {
      id: 3,
      format: "bold",
      type: "mark",
      title: "Bold",
      basic: true,
      themeEnabled: true,
    },
    {
      id: 5,
      format: "underline",
      type: "mark",
      title: "Underline",
    },
    {
      id: 4,
      format: "italic",
      type: "mark",
      title: "Italic",
      basic: true,
      themeEnabled: true,
    },
    {
      id: 6,
      format: "strikethrough",
      type: "mark",
      title: "Strike",
    },
  ],
  [
    {
      id: 7,
      format: "color",
      type: "color-picker",
      title: "Font Color",
    },
    {
      id: 8,
      format: "bgColor",
      type: "color-picker",
      title: "Background Color",
    },
  ],
  [
    {
      id: 9,
      format: "superscript",
      type: "mark",
      title: "Superscript",
    },
    {
      id: 10,
      format: "subscript",
      type: "mark",
      title: "Subcript",
    },
  ],
  [
    {
      id: 11,
      format: "headingOne",
      type: "block",
      title: "H1",
      group: "typography",
    },
    {
      id: 12,
      format: "headingTwo",
      type: "block",
      title: "H2",
      group: "typography",
    },
    {
      id: 13,
      format: "headingThree",
      type: "block",
      title: "H3",
      group: "typography",
    },
    {
      id: 39,
      format: "headingFour",
      type: "block",
      title: "H4",
      group: "typography",
    },
    {
      id: 40,
      format: "headingFive",
      type: "block",
      title: "H5",
      group: "typography",
    },
    {
      id: 41,
      format: "headingSix",
      type: "block",
      title: "H6",
      group: "typography",
    },
    {
      id: 42,
      format: "paragraphOne",
      type: "block",
      title: "Paragraph 1",
      group: "typography",
    },
    {
      id: 43,
      format: "paragraphTwo",
      type: "block",
      title: "Paragraph 2",
      group: "typography",
    },
    {
      id: 44,
      format: "paragraphThree",
      type: "block",
      title: "Paragraph 3",
      group: "typography",
    },
    {
      id: 14,
      format: "doublequote",
      type: "block",
      title: "Double Quote",
      group: "typography",
    },
  ],
  [
    {
      id: 15,
      format: "orderedList",
      type: "block",
      title: "Ordered List",
      group: "list",
    },
    {
      id: 16,
      format: "unorderedList",
      type: "block",
      title: "Bulleted List",
      group: "list",
    },
    {
      id: 43,
      format: "check-list-item",
      type: "block",
      title: "Check List",
      group: "list",
    },
  ],
  [
    {
      id: 17,
      format: "alignLeft",
      type: "block",
      title: "Left Align",
    },
    {
      id: 18,
      format: "alignCenter",
      type: "block",
      title: "Center Align",
    },
    {
      id: 19,
      format: "alignRight",
      type: "block",
      title: "Right Align",
    },
    {
      id: 39,
      format: "alignJustify",
      type: "block",
      title: "Justify",
    },
  ],
  [
    {
      id: 20,
      format: "link",
      type: "link",
    },
    {
      id: 21,
      format: "image",
      type: "embed",
      group: "elements",
    },
    {
      id: 22,
      format: "video",
      type: "embed",
      group: "elements",
    },
    {
      id: 46,
      format: "docsUpload",
      type: "docsUpload",
      group: "elements",
    },
    {
      id: 44,
      lbT: "embed",
      format: "video",
      type: "embed",
      group: "elements",
    },
    {
      id: 49,
      lbT: "embedScript",
      format: "embedScript",
      type: "embedScript",
      group: "elements",
    },
    {
      id: 45,
      format: "topbanner",
      type: "topbanner",
      group: "elements",
    },
    {
      id: 47,
      format: "colorbox",
      type: "colorbox",
      title: "Colorbox",
      group: "elements",
    },
    {
      id: 42,
      type: "table",
      group: "elements",
    },
    {
      id: 51,
      type: "dataView",
      group: "elements",
    },
    {
      id: 48,
      format: "divider",
      type: "divider",
      group: "elements",
    },
  ],
  [
    {
      id: 28,
      format: "grid",
      type: "grid",
      group: "elements",
    },
    {
      id: 50,
      format: "freegrid",
      type: "freegrid",
      group: "elements",
    },
    {
      id: 41,
      format: "emoji",
      type: "emoji",
      group: "elements",
    },
    {
      id: 29,
      format: "newLine",
      type: "newLine",
      group: "elements",
    },
    {
      id: 30,
      format: "accordion",
      type: "accordion",
      group: "list",
      component: "AccordionButton",
    },
    {
      id: 31,
      format: "signature",
      type: "signature",
      group: "elements",
    },
    {
      id: 32,
      format: "button",
      type: "button",
      group: "elements",
    },
    {
      id: 34,
      format: "carousel",
      type: "carousel",
      group: "elements",
    },
    {
      id: 35,
      format: "chipText",
      type: "chip-text",
    },
    {
      id: 37,
      format: "appHeader",
      type: "app-header",
      group: "elements",
    },
    {
      id: 38,
      format: "form",
      type: "form",
      group: "elements",
    },
    // {
    //   id: 33,
    //   format: "pageSettings",
    //   type: "page-settings",
    //   group: "elements",
    // },
  ],
];
