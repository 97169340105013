
export const ensureWrappedVariables = (content) => {
    return content.map((node) => {
        if (node.children) {
            return {
                ...node,
                children: node.children.reduce((result, child, index, arr) => {
                    if (child.type === "variables") {
                        if (
                            index === 0 ||
                            !arr[index - 1].text ||
                            arr[index - 1].text.trim() === ""
                        ) {
                            result.push({ text: "" });
                        }

                        result.push(child);

                        if (
                            index === arr.length - 1 ||
                            !arr[index + 1]?.text ||
                            arr[index + 1]?.text.trim() === ""
                        ) {
                            result.push({ text: "" });
                        }
                    } else {
                        result.push(child);
                    }
                    return result;
                }, []),
            };
        }
        return node;
    });
}


