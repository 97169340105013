import { useEffect, useState } from "react";
import WebFont from "webfontloader";
import { useEditorContext } from "../../hooks/useMouseMove";
import { googleFontList } from "./FontList";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const FontLoader = (props) => {
  const { otherProps, readOnly } = props;
  const { setFontFamilies } = useEditorContext();
  const [loading, setLoading] = useState(true);

  const loadFontsInBatches = (families, batchSize = 5, maxRetries = 3) => {
    let currentIndex = 0;
    let retryCount = 0;
    let hideLoaderOn = 30;

    const loadNextBatch = () => {
      try {
        if (currentIndex >= families?.length) {
          // console.log("All fonts have been loaded");
          setLoading(false);
          return;
        }

        const batch = families?.slice(currentIndex, currentIndex + batchSize);
        const batchWithWeights = batch.map((font) => `${font}:300,400,600,700`);

        WebFont.load({
          google: {
            families: [...batchWithWeights],
          },
          classes: false,
          timeout: 2000,
          active: () => {
            // console.log(`Fonts loaded successfully: ${batch}`);
            currentIndex += batchSize;
            retryCount = 0; // Reset retry count for the next batch
            loadNextBatch();
          },
          inactive: () => {
            // console.log(`Font loading failed for: ${batch}`);
            if (retryCount < maxRetries) {
              retryCount++;
              // console.log(`Retrying batch (${retryCount}/${maxRetries})...`);
              // Retry loading the same batch
              loadNextBatch();
            } else {
              // console.log(
              //   `Max retries reached for batch: ${batch}. Moving to the next batch.`
              // );
              currentIndex += batchSize;
              retryCount = 0;
              loadNextBatch();
            }
          },
        });
      } catch (err) {
        setLoading(false);
      }
    };

    loadNextBatch();
  };

  useEffect(() => {
    let families = [...googleFontList];
    if (!readOnly) {
      otherProps
        ?.services("listGoogleFont", [])
        .then((data) => {
          families = [...(data?.data || [])];
          setFontFamilies({
            id: 1,
            format: "fontFamily",
            type: "fontfamilydropdown",
            options: families || [],
          });

          loadFontsInBatches(families);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
      // setLoading(true);
    } else {
      function correctFontArray(fontString) {
        let fontsArray = fontString.split(",");

        let cleanedFontsArray = [
          ...new Set(fontsArray.map((font) => font.trim())),
        ];

        return cleanedFontsArray;
      }

      function sanitizeFontFamily(fontFamily) {
        const correctedFonts = correctFontArray(fontFamily);
        return correctedFonts.join(", ");
      }

      const elements = Array.from(document?.querySelectorAll("*"));
      const fontSet = new Set();

      elements.forEach((element) => {
        const computedStyles = window?.getComputedStyle(element);
        fontSet.add(sanitizeFontFamily(computedStyles?.fontFamily));
      });

      let families = Array.from(fontSet);
      families = correctFontArray(families.join(", "));
      families = families?.map((font) => font?.replace(/\"/g, ""));
      families = families?.map((font) => font?.replace(", sans-serif", ""));
      families = families.filter((font) => googleFontList.includes(font));
      loadFontsInBatches(families);
    }

    // Set timeout to hide loader after 5 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
    </>
  );
};

export default FontLoader;
