import { Transforms, Node, Editor } from "slate";
const ORDERS_LAYOUT = ["topbanner", "title", "paragraph"];
const ORDERS_LAYOUT_VALIDATIONS = {
  topbanner: (val) => {
    if (val.type !== ORDERS_LAYOUT[0]) {
      return "title";
    } else {
      return val.type;
    }
  },
  title: (val, prev) => {
    if (prev?.type === "topbanner") {
      return "title";
    } else if (prev?.type === "title" && val?.type !== "title") {
      return val.type;
    } else {
      return "paragraph";
    }
  },
  paragraph: (val) => {
    if (val.type === "title") {
      return "paragraph";
    } else {
      return val.type;
    }
  },
};

const withLayout = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length <= 1 && Editor.string(editor, [0, 0]) === "") {
        const { anchor } = editor?.selection || {};
        if (anchor?.offset !== 0) {
          const title = {
            type: "title",
            children: [{ text: "" }],
          };
          Transforms.insertNodes(editor, title, {
            at: path.concat(0),
            select: true,
          });
        }
      }

      if (editor.children.length === 0) {
        const paragraph = {
          type: "paragraph",
          children: [{ text: "" }],
        };
        Transforms.insertNodes(editor, paragraph, { at: path.concat(1) });
        return;
      }

      ORDERS_LAYOUT.forEach((enforce, index) => {
        if (index < editor.children.length) {
          const existsNode = Node.get(editor, [index]);
          let prevNode = null;
          if (index > 0) {
            prevNode = Node.get(editor, [index - 1]);
          }
          const newType = ORDERS_LAYOUT_VALIDATIONS[enforce](
            existsNode,
            prevNode
          );
          if (existsNode?.type !== newType) {
            Transforms.setNodes(editor, { type: newType }, { at: [index] });
          }
        }
      });
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

export default withLayout;
