import React from "react";
import { TextField } from "@mui/material";
import { useDataView } from "../../Providers/DataViewProvider";
import { focusDynamicTableInput } from "../../../../utils/helper";

const NumberType = (props) => {
  const { rowIndex, property, value, readOnly } = props;
  const { onChange } = useDataView();

  const handleChange = (e) => {
    onChange(rowIndex, {
      [property]: e?.target?.value,
    });

    focusDynamicTableInput(e);
  };

  return (
    <TextField
      type="number"
      fullWidth
      className="fe-tv-type_text"
      value={value}
      size="small"
      onChange={handleChange}
      disabled={readOnly}
      id={`tv-number-input-${rowIndex}-${property}`} // * should be unique
    />
  );
};

export default NumberType;
