import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Fade,
  Grid,
  IconButton,
  Popper,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Style
import Style from "./style";
import { PenIcon } from "../icons";
import StyleBuilder from "../../common/StyleBuilder";
import { fontOptions } from "../../utils/font";
import { useEditorTheme } from "../../hooks/useEditorTheme";
import {
  getBreakPointsValue,
  getElementProperty,
  getTRBLBreakPoints,
} from "../../helper/theme";

const buttonStyle = [
  {
    tab: "General",
    value: "size",
    fields: [
      {
        label: "Font Family",
        key: "fontFamily",
        type: "textOptions",
        options: fontOptions,
        webFont: true,
        width: 7,
        renderOption: (option) => {
          return (
            <span style={{ fontFamily: option.value }}>{option.text}</span>
          );
        },
      },
      {
        label: "Font Size",
        key: "textSize",
        type: "fontSize",
        width: 5,
        placeholder: "16px or 1em",
      },
    ],
  },
  {
    tab: "Colors",
    value: "colors",
    fields: [
      {
        label: "Text Color",
        key: "textColor",
        type: "color",
        needPreview: true,
      },
      {
        label: "Button Color",
        key: "bgColor",
        type: "color",
        themeEnabled: true,
      },
    ],
  },
  {
    tab: "Banner Spacing",
    value: "bannerSpacing",
    fields: [
      {
        label: "Banner Spacing",
        key: "bannerSpacing",
        type: "bannerSpacing",
      },
    ],
  },
  {
    tab: "Border",
    value: "border",
    fields: [
      {
        label: "Border Radius",
        key: "borderRadius",
        type: "borderRadius",
      },
    ],
  },
  {
    tab: "Hover Colors",
    value: "hoverColors",
    fields: [
      {
        label: "Hover Text Color",
        key: "textColorHover",
        type: "color",
        needPreview: true,
      },
      {
        label: "Hover Button Color",
        key: "bgColorHover",
        type: "color",
      },
    ],
  },
];

function getBtnSxProps(buttonStyle = {}) {
  const newStyle = { ...buttonStyle };
  if (newStyle?.bannerSpacing) {
    newStyle.padding = newStyle?.bannerSpacing;
  }

  newStyle.borderRadius = {
    ...getBreakPointsValue(
      newStyle?.borderRadius || {},
      null,
      "overrideBorderRadius",
      true
    ),
  };

  newStyle.padding = {
    ...getTRBLBreakPoints(newStyle?.bannerSpacing || {}),
  };

  delete newStyle?.bannerSpacing;

  if (newStyle?.hover) {
    newStyle["&:hover"] = newStyle.hover;
    delete newStyle.hover;
  }

  return newStyle;
}

const MAP_HOVER_KEYS = {
  color: "textColorHover",
  background: "bgColorHover",
};

const MAP_THEME_TO_ELEMENT_KEYS = {
  color: "textColor",
  background: "bgColor",
  fontFamily: (value, prev, buttonEl) => {
    if (!buttonEl?.current) {
      return prev;
    }

    const val = getElementProperty(buttonEl?.current, "font-family");

    return { ...prev, fontFamily: val };
  },
  fontSize: "textSize",
  borderRadius: "borderRadius",
  bannerSpacing: "bannerSpacing",
  lockRadius: "lockRadius",
  lockbannerSpacing: "lockbannerSpacing",
  // fontWeight

  hover: (value = {}, prev = {}) => {
    const props = getProps(value, MAP_HOVER_KEYS);

    return {
      ...prev,
      ...props,
    };
  },
};

const MAP_ELEMENT_TO_THEME_KEYS = {
  textSize: "fontSize",
  textColor: "color",
  bgColor: "background",
  bannerSpacing: "bannerSpacing",
  borderRadius: "borderRadius",
  lockRadius: "lockRadius",
  lockbannerSpacing: "lockbannerSpacing",
  textColorHover: (value, prev) => {
    const hover = { ...(prev?.hover || {}) };
    hover.color = value;

    return {
      ...prev,
      hover,
    };
  },
  bgColorHover: (value, prev) => {
    const hover = { ...(prev?.hover || {}) };
    hover.background = value;

    return {
      ...prev,
      hover,
    };
  },
  fontFamily: "fontFamily",
};

function getProps(buttonStyle, MAP_KEYS, buttonEl) {
  let props = {};

  Object.entries(buttonStyle).forEach(([key, value]) => {
    const elementKey = MAP_KEYS[key];

    if (elementKey) {
      if (typeof elementKey === "string") {
        props[elementKey] = value;
      } else {
        props = elementKey(value, props, buttonEl);
      }
    }
  });

  return props;
}

function convertThemeToElementProps(button = {}, buttonEl) {
  const props = getProps(button, MAP_THEME_TO_ELEMENT_KEYS, buttonEl);

  return props;
}

// icons

const Buttons = (props) => {
  const { className } = props;
  const [anchorEl, setAnchorEl] = useState();

  const { selectedTheme, updateTheme } = useEditorTheme();

  const { button } = selectedTheme?.elementProps || {};

  const buttonEl = useRef();

  const elementProps = convertThemeToElementProps(button, buttonEl);

  // State
  return (
    <Grid className={className}>
      <Accordion className="settingAccordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Buttons
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            className="buttonTypeItem"
            justifyContent={"space-between"}
            alignItems={"center"}
            wrap="nowrap"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <Grid
              item
              style={{ maxWidth: "calc(100% - 50px)", overflowX: "auto" }}
            >
              <Typography
                variant="body1"
                className="fw-600 fs-14"
                style={{ marginBottom: "10px" }}
              >
                Primary Button
              </Typography>

              <Button sx={getBtnSxProps(button)} ref={buttonEl}>
                Button
              </Button>
            </Grid>
            <IconButton className="editIcon">
              <PenIcon />
            </IconButton>
          </Grid>

          {/* <Grid
            container
            className="buttonTypeItem"
            justifyContent={"space-between"}
            alignItems={"center"}
            wrap="nowrap"
          >
            <Grid item>
              <Typography variant="body1" className="fw-600 fs-14">
                Secondary Button
              </Typography>
              <Button variant="contained" sx={{ mt: 1 }} disableElevation>
                Scale Your Business
              </Button>
            </Grid>
            <IconButton className="editIcon">
              <PenIcon />
            </IconButton>
          </Grid>

          <Grid
            container
            className="buttonTypeItem"
            justifyContent={"space-between"}
            alignItems={"center"}
            wrap="nowrap"
          >
            <Grid item>
              <Typography variant="body1" className="fw-600 fs-14">
                Tertiary Button
              </Typography>
              <Button variant="contained" sx={{ mt: 1 }} disableElevation>
                Scale Your Business
              </Button>
            </Grid>
            <IconButton className="editIcon">
              <PenIcon />
            </IconButton>
          </Grid> */}
        </AccordionDetails>
      </Accordion>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        sx={{ zIndex: "100000", background: "white" }}
        placement="left-start"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <StyleBuilder
                title="Button"
                type={"buttonStyle"}
                element={elementProps}
                onSave={(data) => {
                  const props = getProps(data, MAP_ELEMENT_TO_THEME_KEYS);

                  updateTheme(props, {
                    action: "ELEMENT_PROPS_CHANGE",
                    fieldName: "button",
                  });

                  setAnchorEl(null);
                }}
                onClose={() => {
                  setAnchorEl(null);
                }}
                renderTabs={buttonStyle}
                customProps={{ disableEditTheme: true }}
              />
            </div>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
};

export default styled(Buttons)(Style);
