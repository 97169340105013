import React from "react";
import { Select, MenuItem } from "@mui/material";
import {
  addMarkData,
  activeMark,
  getSelectedElementStyle,
} from "../../utils/SlateUtilityFunctions.js";
import { toolbarGroups } from "../toolbarGroups.js";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { googleFontList as defaultFonts } from "../../common/FontLoader/FontList.js";

const allTools = toolbarGroups.flat();
const fontWeight = allTools.find((f) => f.format === "fontWeight");

export const getValue = (editor, format) => {
  switch (format) {
    case "fontFamily": {
      const style = getSelectedElementStyle("font-family", editor);

      return style || defaultFonts[0];
    }
    case "fontWeight": {
      const { options } = fontWeight || {};

      const fontWeightStyle = getSelectedElementStyle("font-weight", editor);

      const selected = options?.find(
        (o) => o.value === fontWeightStyle || o.numVal === fontWeightStyle
      );

      return selected?.value;
    }
    default:
      return activeMark(editor, format);
  }
};

const Dropdown = ({ classes, editor, format, options, width }) => {
  const value = getValue(editor, format);

  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };

  return (
    <Select
      value={value || options?.[0]?.value}
      className="editor-dd"
      onChange={(e) => changeMarkData(e, format)}
      MenuProps={{
        PaperProps: {
          sx: classes?.textOptions,
        },
      }}
      sx={classes.textFormatSelect}
      IconComponent={KeyboardArrowDownRoundedIcon}
    >
      {options.map((item, index) => (
        <MenuItem
          // style={{ fontFamily: item.text }}
          key={index}
          value={item.value}
          sx={classes.textFormatSelectOptions}
        >
          {item.text}
        </MenuItem>
      ))}
    </Select>
  );
};

Dropdown.defaultProps = {
  icon: null,
};

export default Dropdown;
